import gsap from 'gsap';

export default el => {

    const popup = el.firstElementChild;
    const dismissButton = popup.querySelector('button');

    let observer;
    let isVisible = false;
    let isDismissed = false;
    let tl;

    const show = () => {
        if (isVisible || isDismissed) {
            return;
        }
        isVisible = true;
        el.firstElementChild.hidden = false;
        if (tl) {
            tl.kill();
        }
        gsap.killTweensOf(popup);
        tl = gsap.timeline({
            delay: 0.3,
            onComplete() {
                tl = null;
            }
        })
            .fromTo(popup, { opacity: 0 }, { opacity: 1, duration: 0.3 }, 0)
            .fromTo(popup, { y: 30 }, { y: 0, ease: 'Back.easeOut', duration: 0.5 }, 0)
            .fromTo(popup, { scale: 0.85 }, { scale: 1, ease: 'Back.easeOut', duration: 0.5, transformOrigin: 'center bottom' }, 0);
    };

    const hide = () => {
        if (!isVisible) {
            return;
        }
        isVisible = false;
        if (tl) {
            tl.kill();
        }
        gsap.killTweensOf(popup);
        tl = gsap.timeline({
            onComplete: () => {
                el.firstElementChild.hidden = true;
                tl = null;
            }
        })
            .to(popup, { opacity: 0, duration: 0.3 }, 0)
            .to(popup, { y: 30, duration: 0.5, ease: 'Back.easeIn' }, 0);
    };

    const onDismissButtonClick = e => {
        if (isDismissed) {
            return;
        }
        isDismissed = true;
        if (observer) {
            observer.disconnect();
            observer = null;
        }
        hide();
    };

    const init = () => {
        observer = new IntersectionObserver(([{ isIntersecting }]) => {
            if (isIntersecting) {
                show();
            } else {
                hide();
            }
        });
        observer.observe(el);
        dismissButton.addEventListener('click', onDismissButtonClick);
    };

    const destroy = () => {
        if (observer) {
            observer.disconnect();
            observer = null;
        }
        if (tl) {
            tl.kill();
            tl = null;
        }
        dismissButton.removeEventListener('click', onDismissButtonClick);
    };

    return {
        init,
        destroy
    };

};
