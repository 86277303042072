import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import { animatedScroll } from '../lib/helpers';

export default el => {

    const $el = $(el);
    const $inner = $(el.firstElementChild);

    let anchorIds;
    let anchors;
    let offset = 0;
    let prevNavbar;
    let isFixed = false;
    let activeAnchorId = null;
    let preventAutoActiveAnchor = false;
    let intersectingAnchorDivs = new WeakMap();
    let scrollTimeout;

    const fix = () => {
        if (isFixed) {
            return;
        }
        isFixed = true;
        $inner.css({
            position: 'fixed', left: 0, top: `${offset}px`, zIndex: 110
        });
        $el.height($inner.height()).addClass('js-is-fixed');
    };

    const unfix = () => {
        if (!isFixed) {
            return;
        }
        isFixed = false;
        $inner.css({
            position: '', left: '', top: '', zIndex: ''
        });
        $el.height('').removeClass('js-is-fixed');
    };

    const maybeFix = () => {
        const { top } = el.getBoundingClientRect();
        if (prevNavbar) {
            const { height } = prevNavbar.getBoundingClientRect();
            offset = height;
        }
        if (top <= offset) {
            fix();
        } else {
            unfix();
        }
    };

    const setActiveAnchor = (id = null) => {
        const anchorDivs = Object.values(anchors).filter(node => node);
        if (!anchorDivs.length) {
            return;
        }
        let newActiveAnchorId = id;
        // Get all intersecting anchors
        if (!newActiveAnchorId) {
            const intersectingAnchors = anchorDivs.reduce((carry, anchorDiv) => intersectingAnchorDivs.has(anchorDiv) && intersectingAnchorDivs.get(anchorDiv).isIntersecting && intersectingAnchorDivs.get(anchorDiv).intersectionRatio >= 0.1 ? [...carry, intersectingAnchorDivs.get(anchorDiv)] : carry, []);
            if (!intersectingAnchors.length) {
                // If we've scrolled above the first anchor, or there are none intersecting, null it
                if (anchorDivs[0].getBoundingClientRect().top > 0) {
                    newActiveAnchorId = null;
                } else {
                    return;
                }
            } else {
                intersectingAnchors.sort((a, b) => b.intersectionRatio - a.intersectionRatio);
                const intersectingAnchor = intersectingAnchors[0];
                if (intersectingAnchor.target.id) {
                    newActiveAnchorId = intersectingAnchor.target.id;
                }
            }
        }
        if (newActiveAnchorId !== activeAnchorId) {
            $el.find('.js-is-active').removeClass('js-is-active');
        }
        activeAnchorId = newActiveAnchorId;
        if (activeAnchorId) {
            const $activeAnchorLink = $el.find(`a[href="#${activeAnchorId}"]`).eq(0);
            $activeAnchorLink.addClass('js-is-active');
            const draggable = $activeAnchorLink.parent('[data-component="DraggableX"]').data('_draggable');
            if (draggable) {
                draggable.maybeDragToElement($activeAnchorLink.get(0));
            }
        }
    };

    const createScrollTimeout = () => {
        if (scrollTimeout) {
            clearTimeout(scrollTimeout);
        }
        scrollTimeout = setInterval(() => {
            clearTimeout(scrollTimeout);
            scrollTimeout = null;
            preventAutoActiveAnchor = false;
            setActiveAnchor();
        }, 100);
    };

    const onScroll = () => {
        maybeFix();
        if (preventAutoActiveAnchor) {
            createScrollTimeout();
            return;
        }
        setActiveAnchor();
    };

    const onResize = () => {
        maybeFix();
        if (isFixed) {
            $el.height($inner.height());
        }
    };

    const onAnchorLinkClick = e => {
        e.preventDefault();
        const targetId = $(e.triggerTarget).attr('href');
        const $target = $(targetId);
        if (!$target.length) {
            return;
        }
        setActiveAnchor(targetId.substring(1));
        preventAutoActiveAnchor = true;
        animatedScroll($target.get(0), { duration: 0.75, ease: 'Power2.easeInOut' }, $inner.height());
    };

    const anchorObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            const { target, isIntersecting } = entry;
            if (isIntersecting) {
                intersectingAnchorDivs.set(target, entry);
            } else {
                intersectingAnchorDivs.delete(target);
            }
        });
        if (preventAutoActiveAnchor) {
            return;
        }
        setActiveAnchor();
    }, {
        threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]
    });

    const init = () => {
        const $navbars = $(`[data-component="${$el.data('component')}"]`);
        const navbarIndex = $navbars.index(el);
        if (navbarIndex > 0) {
            prevNavbar = $navbars.get(navbarIndex - 1);
        }
        $el.on('click', 'a[data-anchor]', onAnchorLinkClick);
        const $anchors = $el.find('a[data-anchor]');
        anchorIds = [];
        anchors = [];
        $anchors.each(anchor => {
            const id = $(anchor).attr('href').substr(1);
            if (!id) {
                return;
            }
            const targets = $(`#${id}`).get();
            if (targets.length !== 1) {
                $(anchor)
                    .addClass('opacity-50 cursor-default pointer-events-none')
                    .get(0)
                    .removeAttribute('href');
                return;
            }
            anchorIds.push(id);
            anchors.push(targets[0]);
            anchorObserver.observe(targets[0]);
        });
        Viewport.on('scroll', onScroll);
        Viewport.on('resize', onResize);
        onScroll();
    };

    const destroy = () => {
        anchorObserver.disconnect();
        intersectingAnchorDivs = new WeakMap();
        if (scrollTimeout) {
            clearTimeout(scrollTimeout);
            scrollTimeout = null;
        }
        $el.off('click');
        Viewport.off('scroll', onScroll);
        Viewport.on('resize', onResize);
    };

    return {
        init,
        destroy
    };

};
