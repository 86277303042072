import $ from '../core/Dom';
import Components from '../core/Components';

export default (el, { mode: initialMode }) => {

    const $el = $(el);
    const $viewModeToggle = $el.find('button[data-viewmodetoggle]').eq(0);
    const cabinLists = $el.find('[data-cabinslist]').get();

    let currentTabsPanel;

    const initTabsPanel = newMode => {
        const newPanel = $el.find(`[data-tabspanel="${newMode}"]`).get(0);
        if (!newPanel || currentTabsPanel === newPanel) {
            return;
        }
        if (currentTabsPanel) {
            Components.destroy(currentTabsPanel);
            currentTabsPanel.hidden = true;
        }
        currentTabsPanel = newPanel;
        currentTabsPanel.hidden = false;
        $(currentTabsPanel).find('[data-x-component]').each(component => {
            component.setAttribute('data-component', component.getAttribute('data-x-component'));
            component.removeAttribute('data-x-component');
        });
        Components.init(currentTabsPanel);
        if (cabinLists.length) {
            cabinLists.forEach(node => {
                node.hidden = newMode !== 'cabins';
            });
        }
        if (newMode === 'calendar') {
            $viewModeToggle.attr('aria-pressed', 'true');
        } else {
            $viewModeToggle.attr('aria-pressed', 'false');
        }
    };

    const onViewModeToggleClick = () => {
        if (currentTabsPanel && currentTabsPanel.dataset.tabspanel === 'cabins') {
            initTabsPanel('calendar');
        } else {
            initTabsPanel('cabins');
        }
        $viewModeToggle.get(0).focus();
    };

    initTabsPanel(initialMode);

    $viewModeToggle.on('click', onViewModeToggleClick);

    return {
        destroy() {
            if (currentTabsPanel) {
                Components.destroy(currentTabsPanel);
            }
        }
    };

};
