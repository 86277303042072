import gsap from 'gsap';
import $ from '../core/Dom';
import { removeHashbang, triggerNativeEvent } from "../lib/helpers";

export default (el, props = {}) => {

    const { programSlug } = props;

    const $el = $(el);
    const $body = $('body');
    const $programTabs = $el.find(`[id^="${programSlug}-"]`);

    const hasMultiplePrograms = $programTabs.length > 1;

    const deactivateTab = tab => {
        if (!hasMultiplePrograms) {
            return;
        }
        tab.hidden = true;
        tab.setAttribute('tabIndex', -1);
        $(`button[aria-controls="${tab.id}"]`).attr('aria-expanded', 'false');
    };

    const activateTab = tab => {
        if (!tab) {
            return;
        }
        tab.hidden = false;
        tab.removeAttribute('tabIndex');
        $(`button[aria-controls="${tab.id}"]`).attr('aria-expanded', 'true');
        // Scroll to the tab, but account for sticky navbars
        const tabOffset = $(tab).offset().top;
        let scrollTop = tabOffset;
        const $navbars = $('[data-component="Navbar"]');
        $navbars.each(navbar => {
            const $navbar = $(navbar);
            if ($navbar.offset().top < tabOffset) {
                scrollTop -= $navbar.height();
            }
        });
        gsap.to($('html,body').get(), { duration: 0.75, scrollTop: scrollTop + 1, ease: 'Cubic.easeInOut' });
    };

    const setActiveTab = tab => {
        const tabIndex = $programTabs.index(tab);
        $programTabs.get().forEach((tab, index) => {
            if (index === tabIndex) {
                activateTab(tab);
            } else if (!tab.hidden) {
                deactivateTab(tab);
            }
        });
        requestAnimationFrame(() => {
            triggerNativeEvent('resize');
        });
    };

    const onTabLinkClick = e => {
        e.preventDefault();
        e.stopPropagation();
        let tabSelector;
        if (e.triggerTarget.nodeName === 'BUTTON') {
            tabSelector = `#${e.triggerTarget.getAttribute('aria-controls')}`;
        } else {
            tabSelector = e.triggerTarget.getAttribute('href');
        }
        const tab = $el.find(tabSelector).get(0);
        if (tab) {
            setActiveTab(tab);
        }
    };

    const initTabs = () => {
        let activeTab;
        const { hash } = window.location;
        if (hash && hash.length && typeof hash === 'string' && hash[0] === '#') {
            activeTab = $el.find(hash).get(0);
            if (activeTab) {
                setActiveTab(activeTab);
                removeHashbang();
            }
        }
        $body.on('click', `a[href^="#${programSlug}-"],button[data-programtab]`, onTabLinkClick);
    };

    const init = () => {
        initTabs();
    };

    const destroy = () => {
        $body.off('click', onTabLinkClick);
    };

    return {
        init,
        destroy
    };

};
