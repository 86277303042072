import $ from '../core/Dom';

import { openAjaxModal } from "../modules/ModalController";

export default (el, props) => {

    const $el = $(el);

    const id = $el.data('id');
    const { imageIds } = props || {};

    const { protocol, host, pathname } = window.location;
    const path = (pathname || '').replace(/\/$/, '');
    const slideshowUrl = `${protocol}//${host}${path}/gallery?images=${imageIds.join(',')}`;

    const onImageClick = e => {
        e.preventDefault();
        const url = `${slideshowUrl}&start=${id}`;
        openAjaxModal(url, {
            animate: false
        });
    };

    const init = () => {
        $el.on('click', onImageClick);
    };

    const destroy = () => {
        $el.off('click');
    };

    return {
        init,
        destroy
    };

};
