import $ from '../core/Dom';

import gsap from 'gsap';

export default el => {

    const $el = $(el);
    const target = $el.attr('href');

    const onClick = (e) => {
        const $target = $(target);
        if (!$target.length) {
            return true;
        }
        e.preventDefault();
        gsap.to($('html,body').get(), { duration: 0.75, scrollTop: $target.offset().top - ($el.height() / 2), ease: 'Cubic.easeInOut' });
        return false;
    };

    const init = () => {
        $el.on('click', onClick);
    };

    const destroy = () => {
        $el.off('click');
    };


    return {
        init,
        destroy
    };

};
