import $ from '../core/Dom';
import superagent from '../core/request';
import serialize from 'form-serialize';
import URI from 'urijs';
import gsap from 'gsap';

export default el => {

    const id = el.id;
    const $el = $(el);
    const $content = $el.find('[data-content]');
    const spinner = $el.find('[data-spinner]').get(0);

    let request;

    const updateCalendar = (url, scrollToTop = false) => {

        if (request) {
            request.abort();
        }

        const { history } = window;
        if (history && history.pushState) {
            history.pushState(null, null, url);
        }

        spinner.hidden = false;
        gsap.timeline()
            .fromTo(spinner, { opacity: 0 }, { opacity: 1, duration: 0.3 }, 0)
            .fromTo(spinner, { yPercent: 100 }, { yPercent: 0, duration: 0.3, ease: 'Cubic.easeOut' }, 0);
        request = superagent.get(url);
        request.then(({ status, text }) => {
            if (status !== 200 || !text) {
                console.error('Something went wrong');
                return;
            }
            let activeElement = document.activeElement || null;
            const activeElementId = activeElement ? activeElement.id : null;
            const activeElementSelection = activeElement && activeElement.selectionStart && activeElement.selectionEnd ? [
                activeElement.selectionStart,
                activeElement.selectionEnd,
            ] : null;
            const newContent = $('<div />').html(text).find(`#${id} [data-content]`).html();
            gsap.to($content.find('[data-content]').get(0), {
                opacity: 0,
                duration: 0.3,
                onComplete: () => {
                    $content.html(newContent);
                    if (scrollToTop) {
                        const headerHeight = $('[data-component="Header"]').height();
                        const offset = $el.offset().top;
                        window.scrollTo(0, offset - headerHeight);
                    }
                    activeElement = activeElementId ? $el.find(`#${activeElementId}`).get(0) : null;
                    if (!activeElement) {
                        return;
                    }
                    if (activeElementSelection && activeElement.setSelectionRange) {
                        try {
                            activeElement.setSelectionRange(activeElementSelection[0], activeElementSelection[1]);
                        } catch (error) {};
                    }
                    try {
                        activeElement.focus();
                    } catch (error) {};
                    gsap.fromTo($content.find('[data-content]').get(0), { opacity: 0 }, {
                        opacity: 1,
                        duration: 0.5,
                        delay: scrollToTop ? 0.3 : 0,
                        ease: 'Sine.easeIn'
                    });
                }
            });
        })
            .catch(error => {
                console.error(error);
            })
            .then(() => {
                request = null;
                gsap.timeline({
                    onComplete() {
                        spinner.hidden = true;
                    }
                })
                    .to(spinner, { opacity: 0, duration: 0.3 }, 0)
                    .to(spinner, { yPercent: 100, duration: 0.3, ease: 'Cubic.easeIn' }, 0);
            });

    };

    const submitFilters = filters => {
        const queryString = serialize(filters);
        let { protocol, host, pathname } = window.location;
        // Remove pagination from the path
        pathname = pathname.replace(/\/p[0-9]\/$/, '');
        let url = `${protocol}//${host}${pathname}`;
        if (queryString) {
            url += `?${queryString}`;
        }
        updateCalendar(url);
    };

    const onFiltersChange = e => {
        e.preventDefault();
        const { triggerTarget: target } = e;
        const filters = $(target).parent('form').get(0);
        submitFilters(filters);
    };

    const onFiltersSubmit = e => {
        e.preventDefault();
        submitFilters(e.triggerTarget);
    };

    const onResetBtnClick = e => {
        e.preventDefault();
        const $filters = $el.find('[data-filters]');
        $filters.find('select,input').each(input => {
            $(input).val('');
        });
        submitFilters($filters.get(0));
    };

    const onPaginationLinkClick = e => {
        e.preventDefault();
        const { triggerTarget: target } = e;
        const href = new URI($(target).attr('href'));
        const url = href.path() + (href.query() ? `?${href.query()}` : '');
        updateCalendar(url, true);
    };

    const init = () => {
        $el
            .on('change', '[data-filters] input[type="radio"], [data-filters] input[type="checkbox"], [data-filters] select', onFiltersChange)
            .on('submit', '[data-filters]', onFiltersSubmit)
            .on('click', '[data-calendar-reset]', onResetBtnClick)
            .on('click', '[data-pagination] a', onPaginationLinkClick);
    };

    const destroy = () => {
        $el.off('change submit click');
    };

    return {
        init,
        destroy
    };

};
