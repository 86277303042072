/*

 CONFIG


 ~ Usage ~


 import Config from '@vaersaagod/tools/Config';

 // Get a value
 const prop = Config.get('someProp');

 // Get a nested/namespaced value
 const something = Config.get('something.or.other');

 // Set a value (will return value)
 const prop = Config.set('someProp', 123);

 // Set a nested/namespaced value (will return value)
 const something = Config.set('something.or.other', 123);

 // Dump all config values in console
 Config.dump();

 */

import lodash_get from 'lodash/get';
import lodash_set from 'lodash/set';
import lodash_unset from 'lodash/unset';
import cloneDeep from 'lodash/cloneDeep';

let VALUES = window.vrsgConfig || {};
let BACKUP = cloneDeep(VALUES);

const isObject = value  => Object.prototype.toString.call(value) === '[object Object]';

const get = (path, defaultValue = null) => path ? lodash_get(VALUES, path, defaultValue) : VALUES;

const set = (path, value) => {
  lodash_set(VALUES, path, value);
  return value;
}

const reset = () => {
  VALUES = cloneDeep(BACKUP);
};

const unset = path => lodash_unset(VALUES, path);

const dump = path => console.dir( get(path) );

const init = (config = {}) => {
  if (isObject(config) && Object.keys(config).length) {
    VALUES = config;
    BACKUP = cloneDeep(config);
  } else {
    throw 'Config needs to be an object with props!';
  }
}

// Public api for module
export default {
  get,
  set,
  unset,
  reset,
  dump,
  init
};