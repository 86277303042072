import $ from '../core/Dom';
import gsap from 'gsap';
import {Draggable} from 'gsap/Draggable';
import InertiaPlugin from 'gsap/InertiaPlugin';
import Viewport from '../core/Viewport';

gsap.registerPlugin(Draggable);
gsap.registerPlugin(InertiaPlugin);

// TODO: If links or buttons are clicked or receive focus inside the draggable, auto-scroll to them!

export default el => {

    const target = el.querySelector('[data-draggable]') || el.firstElementChild;

    let observer;
    let draggable;

    const maybeDragToElement = element => {
        if (!draggable) {
            return;
        }
        const { left, width } = element.getBoundingClientRect();
        const { left: parentLeft } = element.parentNode.getBoundingClientRect();
        const { left: elLeft, width: elWidth } = el.getBoundingClientRect();

        if (left >= elLeft && ((left - parentLeft) + width) <= elWidth) {
            return;
        }

        let x = (left - parentLeft);

        x *= -1;
        if (x < draggable.minX) {
            x = draggable.minX;
        } else if (x > 0) {
            x = 0;
        }
        gsap.to(draggable.target, {x, duration: 0.3});
    };

    const createDraggable = () => {
        if (draggable) {
            return;
        }
        [draggable] = Draggable.create(target, {
            bounds: el,
            type: 'x',
            inertia: true,
            allowNativeTouchScrolling: true,
            allowContextMenu: true,
            liveSnap: false
        });
    };

    const onResize = () => {
        if (!draggable) {
            return;
        }
        draggable.applyBounds(el);
    };

    const onButtonClick = e => {
        maybeDragToElement(e.currentTarget);
    };

    const init = () => {
        observer = new IntersectionObserver(([{ isIntersecting }]) => {
            if (!isIntersecting) {
                return;
            }
            createDraggable();
            Viewport.on('resize', onResize);
            observer.disconnect();
            observer = null;
        });
        observer.observe(el);
        el.querySelectorAll('button').forEach(button => {
            button.addEventListener('click', onButtonClick);
        });
    };

    const destroy = () => {
        if (observer) {
            observer.disconnect();
            observer = null;
        }
        if (draggable) {
            draggable.kill();
            draggable = null;
            Viewport.off('resize', onResize);
        }
        el.querySelectorAll('button').forEach(button => {
            button.removeEventListener('click', onButtonClick);
        });
    };

    const component = {
        init,
        destroy,
        maybeDragToElement
    };

    $(el).data('_draggable', component);

    return component;

};
