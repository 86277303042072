import $ from '../core/Dom';
import Viewport from '../core/Viewport';

import { loadFlickity } from "../lib/async-bundles";

const SLIDER_SELECTOR = '[data-slideshow-slider]';
const SLIDE_SELECTOR = '[data-slideshow-slide]';
const NEXTBTN_SELECTOR = '[data-next]';
const PREVBTN_SELECTOR = '[data-prev]';
const COUNTER_SELECTOR = '[data-counter] [data-current]';

export default el => {

    const $el = $(el);
    const $slider = $el.find(SLIDER_SELECTOR);
    const $slides = $el.find(SLIDE_SELECTOR);

    let Flickity;
    let slideshow;
    let stageW = Viewport.width;

    let selectedIndex = 0;

    const updateCounter = () => {
        $el.find(COUNTER_SELECTOR).text(selectedIndex + 1);
    };

    const onSlideChange = () => {
        selectedIndex = slideshow.selectedIndex;
        updateCounter();
    };

    const destroyFlickity = () => {
        if (!slideshow) {
            return;
        }
        slideshow.off('change');
        slideshow.destroy();
        slideshow = null;
        $slider.attr('style', '');
        $slides.attr('style', '');
        $el.removeClass('js-has-flickity');
    };

    const initFlickity = () => {

        destroyFlickity();

        $el.addClass('js-has-flickity');

        $slider.css({
            width: `${$slider.width()}px`,
            height: `${$slider.height()}px`
        });

        const slideWidth = $slides.width();
        const slideHeight = $slides.height();

        $slides.css({
            width: `${slideWidth}px`,
            height: `${slideHeight}px`,
            top: 0,
            position: 'absolute'
        })
            .get()
            .forEach((item, index) => {
                $(item)
                    .css({
                        left: `${slideWidth * index}px`
                    });
            });

        slideshow = new Flickity($slider.get(0), {
            pageDots: false,
            prevNextButtons: false,
            setGallerySize: false,
            resize: false,
            wrapAround: true,
            initialIndex: selectedIndex,
            accessibility: true
        });

        slideshow.on('change', onSlideChange);
    };

    const onResize = () => {
        const vw = Viewport.width;
        if (!slideshow || vw === stageW) {
            return;
        }
        stageW = vw;
        initFlickity();
    };

    const onNextBtnClick = e => {
        e.preventDefault();
        if (!slideshow) {
            return;
        }
        slideshow.next();
    };

    const onPrevBtnClick = e => {
        e.preventDefault();
        if (!slideshow) {
            return;
        }
        slideshow.previous();
    };

    const init = () => {
        loadFlickity(flickity => {
            Flickity = flickity;
            initFlickity();
            Viewport.on('resize', onResize);
        });
        $el
            .on('click', NEXTBTN_SELECTOR, onNextBtnClick)
            .on('click', PREVBTN_SELECTOR, onPrevBtnClick);
    };

    const destroy = () => {
        if (slideshow) {
            destroyFlickity();
            Viewport.off('resize', onResize);
        }
        $el.off('click');
    };

    return {
        init,
        destroy
    };

};
