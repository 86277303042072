import $ from '../core/Dom';
import request from '../core/request';
import serialize from "form-serialize";

export default el => {

    const $form = $(el);

    let isSubmitting;

    const resetForm = () => {
        $form.find('input[type="checkbox"]').each(input => input.checked = false);
        $form.find('input[type="email"]').get(0).value = '';
        $form.find('[data-interests]').each(node => {
            node.hidden = true;
        });
    };

    const displayErrorMessage = message => {
        $form.find('[data-error=""]').text(message).get(0).hidden = false;
    };

    const displayErrors = errors => {
        $form.find('[data-error="lists"]').html(errors.lists || '');
        $form.find('[data-error="email"]').html(errors.email || '');
    };

    const resetErrorsAndReceipt = () => {
        $form.find('[data-error=""]').get(0).hidden = true;
        $form.find('[data-receipt]').get(0).hidden = true;
        $form.find('[data-error="lists"],[data-error="email"]').html('');
    };

    const showReceipt = () => {
        $form.find('[data-receipt]').get(0).hidden = false;
    };

    const onSubmit = e => {
        e.preventDefault();
        if (isSubmitting) {
            return;
        }
        isSubmitting = true;
        $form.find('[data-submit] [data-spinner]').get(0).hidden = false;
        $form.find('[data-submit] [data-label] span').css({ opacity: 0 });
        resetErrorsAndReceipt();
        const data = serialize($form.get(0), { hash: false });
        request
            .post(window.location.href)
            .send(data)
            .set('Accept', 'application/json')
            .then(({ status, body }) => {
                if (status !== 200 || !body) {
                    throw new Error();
                }
                const { success, errors, errorMessage } = body;
                if (success) {
                    showReceipt();
                    resetForm();
                    return;
                }
                if (errors) {
                    displayErrors(errors);
                }
                if (errorMessage) {
                    displayErrorMessage(errorMessage);
                }
            })
            .catch(error => {
                // Show generic error
                $form.find('[data-error=""]').get(0).hidden = false;
                console.error(error);
            })
            .then(() => {
                isSubmitting = false;
                $form.find('[data-submit] [data-spinner]').get(0).hidden = true;
                $form.find('[data-submit] [data-label] span').css({ opacity: '' });
            });
    };

    const onAudienceToggleChange = e => {
        const { triggerTarget: listToggle } = e;
        const { audience: audienceId } = listToggle.dataset;
        const interests = $form.find(`[data-interests="${audienceId}"]`).get(0);
        if (!interests) {
            return;
        }
        interests.hidden = !listToggle.checked;
    };

    const init = () => {
        $form
            .on('submit', onSubmit)
            .on('change', 'input[data-audience]', onAudienceToggleChange);
        $form.find('input[data-audience]').each(input => {
            onAudienceToggleChange({ triggerTarget: input });
        });
    };

    const destroy = () => {
        $form.off('submit change');
    };

    return {
        init,
        destroy
    };

};
