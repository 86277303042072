import gsap from 'gsap';
import $ from '../core/Dom';

const TOGGLE_SELECTOR = '[data-accordion-toggle]';

export default (el, props) => {

    const $el = $(el);

    const { id: accordionId = null, animate = false } = props || {}; // TODO

    let $toggle;
    let $target;

    if (accordionId) {
        $toggle = $(`${TOGGLE_SELECTOR}[aria-controls="${accordionId}"]`);
        $target = $(`#${accordionId}`);
    } else {
        $toggle = $el.find(TOGGLE_SELECTOR);
        $target = $toggle.next();
    }

    console.log({ accordionId, $toggle, $target });

    let isOpen = false;

    const open = () => {
        if (isOpen) {
            return;
        }
        isOpen = true;
        $toggle.attr('aria-expanded', 'true');
        $target.get(0).hidden = false;
        $target.attr('tabIndex', '');
        $el.addClass('js-is-open');
    };

    const close = () => {
        if (!isOpen) {
            return;
        }
        isOpen = false;
        $toggle.attr('aria-expanded', 'false');
        $target.get(0).hidden = true;
        $target.attr('tabIndex', -1);
        $el.removeClass('js-is-open');
    };

    const toggle = () => {
        if (isOpen) {
            close();
        } else {
            open();
        }
    };

    const onToggleClick = e => {
        e.preventDefault();
        toggle();
    };

    const init = () => {
        $toggle.on('click', onToggleClick);
        $target.attr('tabIndex', -1);
        $target.get(0).hidden = true;
    };

    const destroy = () => {
        $toggle.off('click');
    };

    return {
        init,
        destroy
    };

};
