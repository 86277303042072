import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import { loadFlickity } from '../lib/async-bundles';

export default el => {

    if (el.offsetParent === null) {
        return null;
    }

    const $el = $(el);
    const $productlist = $el.find('[role="list"]').eq(0);
    const products = Array.from($productlist.get(0).children);

    const nextBtn = $el.find('button[data-next]').get(0);
    const prevBtn = $el.find('button[data-prev').get(0);

    let flickity;
    let flickityDirection = 'next';
    let prevFlickitySlideIndex = 0;
    let viewportW;

    const killScrollable = () => {
        if (!flickity) {
            return;
        }
        flickity.destroy();
        flickity = null;
        $productlist
            .css({
                width: '',
                height: ''
            });
        $productlist.find('a').off('focusin');
    };

    const createScrollable = () => {

        prevFlickitySlideIndex = 0;

        loadFlickity(Flickity => {

            const { width, height } = $productlist.get(0).parentNode.getBoundingClientRect();
            $productlist.css({ width, height });

            flickity = new Flickity($productlist.get(0), {
                contain: true,
                dragThreshold: 15,
                cellAlign: 'left',
                prevNextButtons: false,
                groupCells: true,
                pageDots: false,
                freeScroll: true,
                freeScrollFriction: 0.045,
                percentPosition: false,
                accessibility: false,
                setGallerySize: false,
                adaptiveHeight: false,
                resize: false,
                on: {
                    dragStart() {
                        document.ontouchmove = e => e.preventDefault();
                        $productlist.css({ pointerEvents: 'none' });
                    },
                    dragEnd() {
                        document.ontouchmove = () => true;
                        $productlist.css({ pointerEvents: '' });
                    },
                    select() {
                        this.cells.forEach(({ element }) => {
                            element.removeAttribute('aria-hidden');
                        });
                        if (nextBtn && prevBtn) {
                            const { selectedIndex, slides } = this;
                            if (selectedIndex <= 0) {
                                prevBtn.setAttribute('aria-disabled', 'true');
                            } else {
                                prevBtn.removeAttribute('aria-disabled');
                            }
                            if (selectedIndex >= slides.length - 1) {
                                nextBtn.setAttribute('aria-disabled', 'true');
                            } else {
                                nextBtn.removeAttribute('aria-disabled');
                            }
                        }
                        if (this.selectedIndex > prevFlickitySlideIndex) {
                            flickityDirection = 'next';
                        } else {
                            flickityDirection = 'prev';
                        }
                        prevFlickitySlideIndex = this.selectedIndex;
                    }
                }
            });

            $productlist.find('.flickity-viewport,.flickity-slider').css({
                position: 'relative',
                display: 'flex'
            });

            $productlist.find('a').on('focusin', e => {
                const product = products.find(node => node.contains(e.delegateTarget));
                if (!product) {
                    return;
                }
                const index = products.indexOf(product);
                $productlist.get(0).parentNode.scrollLeft = 0;
                flickity.selectCell(index, false);
            });

            //$productlist.on('focusin', 'a', e => {

                // const product = $products.get().find(node => {
                //     console.log({ node });
                //     return node.contains(e.triggerTarget);
                // });
                // console.log({ product });
                // if (!product) {
                //     return;
                // }
                // const productIndex = Array.prototype.indexOf.call($products.get(), product);
                // console.log({ productIndex });
                // const product = $(e.triggerTarget).parent('[data-product]').get(0);
                // if (!product) {
                //     return;
                // }
                // const index = Array.prototype.indexOf.call(tabs, tab)
            //});

            // $productlist.find('a').on('focusin', e => {
            //     // const tab = $(e.delegateTarget).parent('[role="presentation"]').find('[role="tab"]').get(0);
            //     // if (!tab) {
            //     //     return;
            //     // }
            //     // const index = getTabIndex(tab);
            //     // $productlist.get(0).parentNode.scrollLeft = 0;
            //     // if (!intersectingTabs.has(tab)) {
            //     //     flickity.selectCell(index, false);
            //     // }
            // });
        });

    };

    const onResize = () => {
        if (Viewport.width === viewportW) {
            return;
        }
        killScrollable();
        viewportW = Viewport.width;
        createScrollable();
    };

    const onPrevBtnClick = () => {
        if (!flickity) {
            return;
        }
        flickity.previous();
    };

    const onNextBtnClick = () => {
        if (!flickity) {
            return;
        }
        flickity.next();
    };

    onResize();

    Viewport.on('resize', onResize);

    if (nextBtn && prevBtn) {
        $el.on('click', 'button[data-prev]', onPrevBtnClick);
        $el.on('click', 'button[data-next]', onNextBtnClick);
    }

    return {
        destroy() {
            $productlist.off('click keydown');
            $el.off('click');
            killScrollable();
        }
    };
};
