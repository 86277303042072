import gsap from 'gsap';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Viewport from '../core/Viewport';

import * as eventKeys from '../lib/events';

import { ALERT_CLOSED, ALERT_CLOSING } from '../lib/events';

export default (el, props) => {

    const $el = $(el);

    const { navId } = props || {};

    const $nav = $el.find('[data-nav]');
    const $navMain = $el.find('[data-navmain]');
    const $navToggle = $el.find('[data-navtoggle]');
    const $overlay = $el.find('[data-overlay]');

    let navIsOpen = false;
    let focusedElement;
    let navTimeline;

    const onAfterNavClose = () => {
        $nav
            .attr('tabIndex', -1)
            .get(0).hidden = true;
        $overlay.get(0).hidden = true;
        $navToggle.attr('aria-expanded', 'false');
        navTimeline.kill();
        navTimeline = null;
        $navMain.get(0).scrollTop = 0;
        const navMainInner = $navMain.get(0).firstElementChild;
        gsap.set([$navMain.get(0), $overlay.get(0), navMainInner], { clearProps: 'all' });
        Dispatch.emit(eventKeys.MENU_AFTER_CLOSE);
    };

    const setOffsetY = () => {
        if (!navIsOpen) {
            return;
        }
        const offsetY = $('[data-component="Header"]').offset().top;
        $navMain.css({
            paddingTop: `${offsetY}px`
        });
    };

    const closeNav = (tween = true) => {
        if (!navIsOpen) {
            return;
        }
        navIsOpen = false;

        Dispatch.emit(eventKeys.MENU_BEFORE_CLOSE);

        Viewport.releaseTabbing(focusedElement);

        if (!tween) {
            onAfterNavClose();
            return;
        }
        requestAnimationFrame(() => {
            navTimeline.timeScale(1.75);
            navTimeline.reverse();
        });
    };

    const openNav = (tween = true) => {

        if (navIsOpen) {
            return;
        }

        navIsOpen = true;

        Dispatch.emit(eventKeys.MENU_BEFORE_OPEN);

        focusedElement = document.activeElement || null;

        $nav.get(0).hidden = false;
        $overlay.get(0).hidden = false;

        $navToggle.attr('aria-expanded', 'true');

        setOffsetY();

        try {
            Viewport.lockTabbing([$nav.parent('nav').get(0)], $navToggle.get(0));
        } catch (error) {
            console.warn(error);
        }

        if (!navTimeline) {
            const navMainInner = $navMain.get(0).firstElementChild;
            navTimeline = gsap.timeline({
                paused: true,
                onReverseComplete: () => {
                    onAfterNavClose();
                },
                onComplete: () => {
                    Dispatch.emit(eventKeys.MENU_AFTER_OPEN);
                }
            })
                .to($nav.get(0), 0.3, { opacity: 1 }, 0)
                .fromTo($overlay.get(0), 0.5, { opacity: 0.0001 }, { opacity: 0.9999 }, 0)
                .fromTo($navMain.get(0), 0.3, { xPercent: 100 }, {
                    xPercent: 40,
                    ease: 'Cubic.easeIn'
                }, 0)
                .to($navMain.get(0), 0.7, { xPercent: 0.0001, ease: 'Quint.easeOut' }, 0.3)
                .fromTo(navMainInner, 0.85, { xPercent: 33 }, {
                    xPercent: 0.0001,
                    ease: 'Quint.easeOut'
                }, 0.15)
                .fromTo(navMainInner, 0.35, { opacity: 0.0001 }, { opacity: 0.9999 }, 0.25);
        }

        if (!tween) {
            navTimeline.pause(navTimeline.totalDuration());
            return;
        }
        navTimeline.timeScale(1);
        navTimeline.play();

    };

    const toggleNav = (tween = true) => {
        if (!navIsOpen) {
            openNav(tween);
        } else {
            closeNav(tween);
        }
    };

    /**
     * ====== Event handlers
     */
    const onBodyKeyUp = e => {
        const key = e.which || e.keyCode || null;
        if (key === 27) {
            closeNav();
        }
    };

    const onNavToggleClick = () => {
        toggleNav();
    };

    const onResize = () => {
        setOffsetY();
    };

    const onAlertClose = () => {
        setOffsetY();
    };

    /**
     * Init component
     */
    const init = () => {

        $el
            .on('click', '[data-navtoggle], [data-nav] [data-close]', onNavToggleClick)
            .on('click', '[data-overlay]', onNavToggleClick);

        $('body')
            .on('keyup', onBodyKeyUp);

        $el.find('[data-overlay]')
            .attr('role', 'button')
            .get(0)
            .removeAttribute('href');

        Viewport.on('resize', onResize);

        Dispatch.on(ALERT_CLOSING, onAlertClose);
        Dispatch.on(ALERT_CLOSED, onAlertClose);

        onResize();

        // Account for the menu being opened already before the JS had the chance to boot
        if (navId && window.location.hash === `#${navId}`) {
            openNav(false);
            window.location.hash = '';
            if (window.history && window.history.replaceState) {
                window.history.replaceState(null, document.title, `${window.location.pathname}${window.location.search}`);
            }
        }
    };

    /**
     * Destroy component
     */
    const destroy = () => {
        closeNav(false);
        Viewport.off('resize', onResize);
        Dispatch.off(ALERT_CLOSING, onAlertClose);
        Dispatch.off(ALERT_CLOSED, onAlertClose);
        $('body').off('keyup', onBodyKeyUp);
        $el.off('click change');
    };

    return {
        init,
        destroy
    };

};
