import gsap from 'gsap';
import $ from '../core/Dom';
import agent from '../core/request';
import Viewport from '../core/Viewport';
import Components from '../core/Components';
import Dispatch from '../core/Dispatch';

const CONTROLS_SELECTOR = '[data-controls]';
const SUMMARY_SELECTOR = '[data-summary]';
const RESULTS_SELECTOR = '[data-results]';
const HIT_SELECTOR = '[data-hit]';

export default (el, props = {}) => {

    const $el = $(el);
    const $form = $el.find('form');
    const $controls = $el.find(CONTROLS_SELECTOR);
    const $summary = $el.find(SUMMARY_SELECTOR);
    const $results = $el.find(RESULTS_SELECTOR);

    let filter = props.filter || null;
    let request;
    let focusAfterUpdateSummary;

    const isInModal = () => !!$el.parent('.modal').length;

    const isSmall = () => $controls.width() >= Viewport.width;

    const updateResults = html => {
        const $html = $('<div />').html(html);
        const $newResults = $html.find(RESULTS_SELECTOR);
        if (!isInModal() || isSmall()) {
            $results.html($newResults.html());
            return;
        }
        const hadResults = !!$results.find(HIT_SELECTOR).length;
        const haveNewResults = !!$newResults.find(HIT_SELECTOR).length;
        if (!hadResults && haveNewResults) {
            // Intro the results panel
            $results.html($newResults.html());
            const timeline = gsap.timeline({ paused: true })
                .fromTo($results.get(0), 1, { xPercent: 100 }, {
                    xPercent: 0,
                    ease: 'Quint.easeInOut'
                }, 0)
                .fromTo($results.find(HIT_SELECTOR).get(), 0.5, { opacity: 0 }, { opacity: 1 }, 0.75);
            requestAnimationFrame(() => {
                timeline.play();
            });
        } else {
            // Just update the content
            if (haveNewResults) {
                $results.html($newResults.html());
            }
        }
    };

    const updateSummary = html => {
        const $html = $('<div />').html(html);
        try {
            Components.destroy($summary);
        } catch (error) {
            // Don't care
        }
        $summary.html($html.find(SUMMARY_SELECTOR).html());
        requestAnimationFrame(() => {
            Components.init($summary);
        });
        // Attempt to retain focus
        if (focusAfterUpdateSummary === 'filter') {
            $controls.find('[data-component="Listbox"] button').get(0).focus();
        } else if (focusAfterUpdateSummary) {
            $controls.find(`#${focusAfterUpdateSummary}`).get(0).focus();
        } else {
            $controls.find('input[name="q"]').get(0).focus();
        }
        focusAfterUpdateSummary = null;
    };

    const submitForm = () => {
        $el.addClass('js-is-loading');
        if (request) {
            request.abort();
        }
        const q = $form.find('input[name="q"]').val();
        const url = $form.attr('action');
        request = agent.get(url).query({ q, filter });
        request
            .then(({ status, text }) => {
                if (status !== 200 || !text) {
                    throw new Error();
                }
                updateSummary(text);
                updateResults(text);
            })
            .catch(error => {
                console.log({ error });
            })
            .then(() => {
                request = null;
                $el.removeClass('js-is-loading');
            });
        if (window.ga && request.url) {
            try {
                window.ga('send', 'pageview', request.url);
                console.info('tracked pageview to ga', request.url);
            } catch (error) {
                console.error(error);
            }
        }
    };

    const onFormSubmit = e => {
        e.preventDefault();
        submitForm();
    };

    const setFilter = value => {
        if (value === filter) {
            return;
        }
        filter = value;
        submitForm();
    };

    const onListboxChange = (key, data) => {
        const { id, name, value } = data;
        if (!$el.find(`#${id}`).length) {
            return;
        }
        if (name === 'filter') {
            setFilter(value);
            focusAfterUpdateSummary = 'filter';
        }
    };

    const onFilterBtnClick = e => {
        const { triggerTarget: target } = e;
        const value = target.dataset.filter;
        setFilter(value);
        focusAfterUpdateSummary = target.id;
    };

    const init = () => {
        $form.on('submit', onFormSubmit);
        $controls.on('click', 'button[data-filter]', onFilterBtnClick);
        // Maybe do intro animation, if we're in a modal
        Dispatch.on('listbox:change', onListboxChange);
        const doIntro = !!isInModal() && !isSmall();
        if (doIntro) {
            requestAnimationFrame(() => {
                gsap.timeline()
                    .fromTo(el, 0.3, { duration: 0.3, xPercent: 100 }, {
                        xPercent: 40,
                        ease: 'Cubic.easeIn'
                    }, 0)
                    .to(el, 0.7, { xPercent: 0.0001, ease: 'Quint.easeOut' }, 0.3);
            });
        }
    };

    const destroy = () => {
        $form.off('submit');
        $controls.off('click');
        Dispatch.off('listbox:change', onListboxChange);
    };

    return {
        init,
        destroy
    };

};
