import gsap from 'gsap';
import serialize from 'form-serialize';

import Viewport from '../core/Viewport';
import $ from '../core/Dom';
import agent from '../core/request';

import { nl2br, t } from '../lib/helpers';

export default el => {

    const $el = $(el);
    const $form = $el.find('form');

    let request;
    let isSubmitting;

    const isInModal = () => !!$el.parent('.modal').length;

    const isSmall = () => $el.width() >= Viewport.width;

    const displayReceiptFromHtml = html => {
        const $html = $('<div/>').append(html);
        const $container = $el.find('[data-receipt]');
        $container.html($html.html());
    };

    const setErrors = (errors, message) => {

        console.log({ errors, message });

        errors = errors || {};

        const hasErrors = !!Object.values(errors).length || message;

        const usedErrors = [];

        $el.find('[aria-describedby]').each(input => {

            const name = input.name || input.dataset.name || null;

            if (!name || usedErrors.indexOf(name) > -1) {
                return;
            }

            const errorsId = `${input.getAttribute('id')}-errors`;
            const $error = $(`#${errorsId}`);

            if (!$error.length) {
                return;
            }

            usedErrors.push(name);

            let errorList = '';

            [].concat(errors[name] || []).forEach(error => {
                errorList += `<li>${error}</li>`;
            });

            if (errorList) {
                $error.find('ul').html(errorList).get(0).hidden = false;
            } else {
                $error.find('ul').html('').get(0).hidden = true;
            }

        });
        const $errorsDialog = $el.find('[data-errors]');
        if (!hasErrors) {
            $el.removeClass('js-has-errors');
            $errorsDialog
                .attr('tabIndex', -1)
                .get(0).firstElementChild.hidden = true;
        } else {
            $el.addClass('js-has-errors');
            let errorLinks = '';
            Object.keys(errors).forEach(name => {
                const input = $form.find(`input[id][name^="${name}"]`).get(0);
                if (!input) {
                    return;
                }
                const { id } = input;
                const label = $form.find(`label[for="${id}"]`).get(0);
                if (!label) {
                    return;
                }
                errorLinks += `<li class="mb-5"><a href="#${id}">${$(label).text().split('*').shift()}: ${errors[name]}</a></li>`;
            });
            const $errorsDialogHeading = $errorsDialog.find('h1,h2,h3');
            const $errorList = $errorsDialog.find('[data-errorlist]');
            $errorsDialog.get(0).firstElementChild.hidden = false;
            $errorsDialogHeading.html(nl2br(message));
            $errorList.html(errorLinks);
            $errorsDialog.attr('tabIndex', 0).focus();
        }
    };

    const onFormSubmit = e => {

        e.preventDefault();

        if (isSubmitting) {
            return;
        }

        isSubmitting = true;
        $el.addClass('js-is-loading');

        const data = serialize($form.get(0), { hash: false });
        if (request) {
            request.abort();
        }

        agent
            .post(window.location.href)
            .send(data)
            .set('Accept', 'application/json')
            .then(({ status, body }) => new Promise((resolve, reject) => {
                if (status !== 200) {
                    reject();
                    return;
                }
                const { errors, message, redirect = '' } = body || {};
                if (errors || message) {
                    setErrors(errors, message);
                    resolve();
                    return;
                }
                if (!redirect) {
                    displayReceiptFromHtml('');
                    resolve();
                    return;
                }
                agent
                    .get(`/${redirect}`)
                    .then(({ status, text }) => {
                        setErrors(null);
                        if (status !== 200) {
                            reject();
                            return;
                        }
                        if (!isInModal()) {
                            window.location.href = `/${redirect}`;
                        } else {
                            isSubmitting = false;
                            $el.removeClass('js-is-loading');
                            displayReceiptFromHtml(text);
                        }
                        resolve();
                    })
                    .catch(error => {
                        reject(error);
                    });
            }))
            .catch(error => {
                setErrors(null, t('An unknown error occurred. Please try again later!'));
                console.error(error);
            })
            .then(() => {
                request = null;
                isSubmitting = false;
                $el.removeClass('js-is-loading');
            });
        if (window.gtag) {
            try {
                const identifier = $form.find('input[type="hidden"][name="identifier"]').val() || null;
                const referrerEntryTitle = $form.find('input[type="hidden"][name="referrerEntry"]').val() || null;
                window.gtag('event', 'submit_contact_form', {
                    entry_name: referrerEntryTitle,
                    date: new Date().getTime(),
                    identifier
                });
            } catch (error) {
                console.error(error);
            }
        }
        if (window.ga) {
            try {
                window.ga('send', 'event', 'Contact form', 'submit', window.location.href);
            } catch (error) {
                console.error(error);
            }
        }
    };

    const init = () => {
        $form.on('submit', onFormSubmit);

        // Maybe do intro animation, if we're in a modal
        const doIntro = !!isInModal() && !isSmall();
        if (doIntro) {
            requestAnimationFrame(() => {
                gsap.timeline()
                    .fromTo(el, 0.3, { duration: 0.3, xPercent: 100 }, {
                        xPercent: 40,
                        ease: 'Cubic.easeIn'
                    }, 0)
                    .to(el, 0.7, { xPercent: 0.0001, ease: 'Quint.easeOut' }, 0.3);
            });
        }
    };

    const destroy = () => {
        $form.off('submit');
        if (request) {
            request.abort();
            request = null;
        }
    };

    return {
        init,
        destroy
    };

};
