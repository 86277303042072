import $ from '../core/Dom';
import Components from '../core/Components';


export default el => {

    const $el = $(el);
    const map = $el.find('[data-panel="map"]').get(0);
    const itinerary = $el.find('[data-panel="itinerary"]').get(0);

    const showMap = () => {
        if (!map || !map.hidden) {
            return;
        }
        map.hidden = false;
        Components.init(map);
        if (itinerary) {
            Components.destroy(itinerary);
            itinerary.hidden = true;
        }
    };

    const showItinerary = () => {
        if (!itinerary || !itinerary.hidden) {
            return;
        }
        itinerary.hidden = false;
        Components.init(itinerary);
        if (map) {
            Components.destroy(map);
            map.hidden = true;
        }
    };

    const onModeToggleClick = e => {
        const { triggerTarget: toggle } = e;
        if (toggle.getAttribute('aria-pressed') === 'true') {
            toggle.setAttribute('aria-pressed', 'false');
            showMap();
        } else {
            toggle.setAttribute('aria-pressed', 'true');
            showItinerary();
        }
    };

    $el.on('click', 'button[data-modetoggle]', onModeToggleClick);

    return {
        destroy() {
            $el.off('click');
        }
    };

};
