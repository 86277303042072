/*
 *   World's Thinnest Wrapper around Superagent
 *   https://visionmedia.github.io/superagent/
 *
 *   Usage:
 *
 *   import request from '@vaersaagod/tools/Request';
 *
 *   ...
 *
 *   request
 *       .post(someEndpoint)
 *       .type('application/json')
 *       .send(JSON.stringify(someData))
 *       .then(res => {
 *           const {status, body} = res || {};
 *           if (status === 200 && body) {
 *               // Do something
 *           } else {
 *               // Do something else
 *           }
 *       })
 *       .catch(error => {
 *           console.error(error);
 *       });
 *
 */

import request from 'superagent';

export default {
    ...request,
    // IE10 caches GET requests by default, which can lead to some troubles. This adds the current timestamp to the request URL, preventing this
    get: (url, opts = {
        cache: false,
        ajax: true
    }) => request.get(url).query(!opts.cache ? new Date().getTime() : null).set(opts.ajax ? {'X-Requested-With': 'XMLHttpRequest'} : null),
    post: (url, opts = {
        ajax: true
    }) => request.post(url).set(opts.ajax ? {'X-Requested-With': 'XMLHttpRequest'} : null)
};
