import $ from '../core/Dom';
import { triggerNativeEvent } from "../lib/helpers";

export default el => {

    const $el = $(el);
    const $select = $el.find('select');

    const setValue = value => {
        const select = $select.get(0);
        value = value.toString();
        if (select && select.value !== value) {
            select.value = value;
            triggerNativeEvent('change', $select.get(0));
        }
        if (!value) {
            $el.find('[role="listbox"]').each(listbox => listbox.removeAttribute('aria-activedescendant'));
            $el.find('[role="option"]').removeClass('js-is-selected');
            return;
        }
        const option = $el.find(`[role="option"][data-value="${value}"]`).get(0);
        const listbox = $(option).parent('[role="listbox"]').get(0);
        if (!listbox || $(listbox).attr('aria-activedescendant') === option.id) {
            return;
        }
        $(listbox).attr('aria-activedescendant', option.id);
        $(option).addClass('js-is-selected');
        $el.find(`[role="listbox"]:not([id="${listbox.id}"])`).each(listbox => listbox.removeAttribute('aria-activedescendant'));
        $el.find(`[role="option"]:not([data-value="${value}"])`).removeClass('js-is-selected');
    };

    const closeListbox = listbox => {
        if (listbox.hidden) {
            return;
        }
        listbox.hidden = true;
        $el.find(`button[aria-controls="${listbox.id}"]`).attr('aria-expanded', 'false');
        $(listbox).find('[role="listbox"]:not([hidden])').each(nestedListbox => closeListbox(nestedListbox));
    };

    const openListbox = listbox => {
        if (!listbox.hidden) {
            return;
        }
        listbox.hidden = false;
        $el.find(`button[aria-controls="${listbox.id}"]`).attr('aria-expanded', 'true');
    };

    const closeAll = () => {
        $el.find('[role="listbox"]').each(listbox => closeListbox(listbox));
    };

    const onSelectChange = () => {
        setValue($select.val());
    };

    const onToggleClick = e => {
        const { triggerTarget: toggle } = e;
        const target = toggle.nextElementSibling;
        if (!target) {
            return;
        }
        if (target.hidden) {
            openListbox(target);
        } else {
            closeListbox(target);
        }
    };

    const onOptionClick = e => {
        const { triggerTarget: option } = e;
        const $option = $(option);
        if ($option.hasClass('js-is-selected')) {
            setValue('');
            return;
        }
        setValue($option.data('value'));
    };

    const init = () => {
        $el
            .on('click', 'button[aria-expanded]', onToggleClick)
            .on('click', '[role="option"]', onOptionClick);
        if ($select.length) {
            $select.get(0).addEventListener('change', onSelectChange);
        }
    };

    const destroy = () => {
        $el.off('click');
        if ($select.length) {
            $select.get(0).removeEventListener('change', onSelectChange);
        }
    };

    $el.data({
        accordionInput: {
            setValue,
            closeAll
        }
    });

    return {
        init,
        destroy
    };
};
