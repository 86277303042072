import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import Config from '../core/Config';

import {clamp} from '../lib/helpers';

import {
    MENU_BEFORE_OPEN,
    MENU_BEFORE_CLOSE,
    MENU_AFTER_CLOSE,
    SCROLL_LOCKED,
    SCROLL_RELEASED, ALERT_CLOSED, ALERT_CLOSING
} from '../lib/events';

import gsap from 'gsap';

import {triggerSearchModal} from "../lib/helpers";

const Header = el => {

    const $el = $(el);
    const $inner = $el.find('[data-inner]');

    const $searchBtn = $el.find('[data-searchbtn]');

    const $logo = $el.find('[data-logo]').eq(0);
    let logoHeight = $logo.height();

    const navbarsInDom = $('[data-component="Navbar"]').get();

    let height = $inner.height();
    let currentY = 0;
    let prevScrollTop = Viewport.scrollTop;

    let searchIsOpen = false;
    let navIsOpen = false;
    let alertIsClosing = false;
    let offsetY = 0;

    const onScroll = () => {

        let scrollTop = Viewport.scrollTop - offsetY;
        if (scrollTop < 0) {
            scrollTop = 0;
        }

        const direction = prevScrollTop < scrollTop ? 'down' : 'up';
        const diff = Math.abs(prevScrollTop - scrollTop);

        let y;
        const inner = $inner.get(0);

        let hasStickyNavbar = false;
        for (let i = 0; i < navbarsInDom.length; ++i) {
            if (navbarsInDom[i].getBoundingClientRect().top <= 0) { // if (navbarsInDom[i].getBoundingClientRect().top - height <= 0) {
                hasStickyNavbar = true;
                break;
            }
        }

        let logoHeightDiff = logoHeight - height;
        if (logoHeightDiff <= 0) {
            logoHeightDiff = false;
        }

        if (!hasStickyNavbar && (navIsOpen || searchIsOpen || alertIsClosing || direction === 'up')) {

            y = clamp(currentY + diff, -height, 0);

            if (y !== currentY) {
                if (navIsOpen || scrollTop > height) {
                    y = 0;
                    gsap.to(inner, {
                        duration: 0.5,
                        y,
                        ease: 'Power2.easeOut'
                    });
                    if (logoHeightDiff) {
                        gsap.to($logo.get(0).firstElementChild, {y: 0, duration: 0.5, ease: 'Power2.easeOut'})
                    }
                } else {
                    gsap.set(inner, {y});

                    if (logoHeightDiff) {
                        const perc = y / -height;
                        gsap.set($logo.get(0).firstElementChild, {y: -(logoHeightDiff * perc) });
                    }
                }

            }

        } else {

            y = clamp(currentY - diff, -height, 0);

            if (y !== currentY) {
                if (currentY >= 0 && scrollTop > height) {

                    if (diff < 20) {
                        return;
                    }

                    y = -height;

                    gsap.to(inner, {
                        duration: 0.5,
                        y
                    });

                    if (logoHeightDiff) {
                        gsap.to($logo.get(0).firstElementChild, {y: -logoHeightDiff, duration: 0.5 });
                    }

                } else {

                    gsap.set(inner, {y});

                    if (logoHeightDiff) {
                        const perc = y / -height;
                        gsap.set($logo.get(0).firstElementChild, {y: -(logoHeightDiff * perc) });
                    }

                }

            }

        }

        currentY = y;
        prevScrollTop = scrollTop;

        if (scrollTop > height) {
            $el.addClass('js-is-scrolled');
        } else {
            $el.removeClass('js-is-scrolled');
        }

    };

    const onResize = () => {
        height = $inner.height();
        logoHeight = $logo.height();
        offsetY = $el.offset().top;
        $inner.css({
            top: `${offsetY}px`
        });
        onScroll(true);
    };

    const onScrollLocked = () => {
        const {scrollbarWidth} = Config.get();
        if (!scrollbarWidth) {
            return;
        }
        $inner.css({borderRight: `${scrollbarWidth}px solid transparent`});
    };

    const onScrollReleased = () => {
        const {scrollbarWidth} = Config.get();
        if (!scrollbarWidth) {
            return;
        }
        $inner.css({borderRight: ''});
    };

    const onBeforeMenuOpen = () => {
        navIsOpen = true;
        $el.addClass('js-nav-open');
    };

    const onBeforeMenuClose = () => {
        $el.addClass('js-nav-closing');
    };

    const onAfterMenuClose = () => {
        navIsOpen = false;
        $el.removeClass('js-nav-open js-nav-closing');
    };

    const onAlertClosed = () => {
        onResize();
        alertIsClosing = false;
    };

    const onAlertClosing = () => {
        alertIsClosing = true;
        onResize();
    };

    const onSearchBtnClick = e => {
        e.preventDefault();
        triggerSearchModal();
    };

    const initSearchBtn = () => {
        if (!$searchBtn.length) {
            return;
        }
        $searchBtn
            .attr({role: 'button', tabIndex: 0, 'aria-expanded': 'false'})
            .get(0)
            .removeAttribute('href');
    };

    const init = () => {

        gsap.set($inner.get(0), { y: 0 });

        Viewport.on('resize', onResize);
        Viewport.on('scroll', onScroll);

        $el
            .on('click', '[data-searchbtn]', onSearchBtnClick)
            .on('keyup', '[data-searchbtn]', e => {
                const key = e.which || e.keyCode || null;
                if (key === 13) {
                    onSearchBtnClick(e);
                }
            });

        Dispatch.on(MENU_BEFORE_OPEN, onBeforeMenuOpen);
        Dispatch.on(MENU_BEFORE_CLOSE, onBeforeMenuClose);
        Dispatch.on(MENU_AFTER_CLOSE, onAfterMenuClose);

        Dispatch.on(SCROLL_LOCKED, onScrollLocked);
        Dispatch.on(SCROLL_RELEASED, onScrollReleased);

        Dispatch.on(ALERT_CLOSING, onAlertClosing);
        Dispatch.on(ALERT_CLOSED, onAlertClosed);

        initSearchBtn();

        onResize();

    };

    const destroy = () => {

        Viewport.off('resize', onResize);
        Viewport.off('scroll', onScroll);

        Dispatch.off(MENU_BEFORE_OPEN, onBeforeMenuOpen);
        Dispatch.off(MENU_BEFORE_CLOSE, onBeforeMenuClose);
        Dispatch.off(MENU_AFTER_CLOSE, onAfterMenuClose);
        Dispatch.off(SCROLL_LOCKED, onScrollLocked);
        Dispatch.off(SCROLL_RELEASED, onScrollReleased);
        Dispatch.off(ALERT_CLOSING, onAlertClosing);
        Dispatch.off(ALERT_CLOSED, onAlertClosed);

        $el.off('click keyup');


    };

    return {
        init,
        destroy
    };
};

export default Header;
