import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import scrollLock from 'scroll-lock';
import get from 'lodash/get';
import Dispatch from '../core/Dispatch';
import Config from '../core/Config';

import { openAjaxModal } from '../modules/ModalController';

import { SCROLL_LOCKED, SCROLL_RELEASED, PROGRAMMATIC_SCROLL_START, PROGRAMMATIC_SCROLL_END } from './events';

let scrollIsLocked = false;

gsap.registerPlugin(ScrollToPlugin);

export const lockScroll = scrollableTarget => {
    if (scrollIsLocked) {
        console.warn('Scroll is already locked');
        return;
    }
    scrollIsLocked = true;
    scrollLock.disablePageScroll(scrollableTarget);
    Dispatch.emit(SCROLL_LOCKED);
};

export const releaseScroll = scrollableTarget => {
    if (!scrollIsLocked) {
        console.warn('Tried to unlock scroll, but it wasnt locked');
        return;
    }
    scrollIsLocked = false;
    scrollLock.enablePageScroll(scrollableTarget);
    Dispatch.emit(SCROLL_RELEASED);
};

export const clamp = (number, min, max) => Math.max(min, Math.min(number, max));

export const queryStringToObject = query => {
    return query ? (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params, param) => {
        const [key, value] = param.split('=');
        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
        return params;
    }, {}) : {};
};

export const objectToQueryString = obj => {
    if (!obj) {
        return '';
    }
    return Object.keys(obj).map(k => {
        return obj[k] ? `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}` : null;
    }).filter(k => !!k).join('&');
};

export const recursiveObjectToQueryString = object => {
    function reducer(obj, parentPrefix = null) {
        return function (prev, key) {
            const val = obj[key];
            const encodedKey = encodeURIComponent(key);
            const prefix = parentPrefix ? `${parentPrefix}[${encodedKey}]` : encodedKey;

            if (val == null || typeof val === 'function') {
                prev.push(`${prefix}=`);
                return prev;
            }

            if (typeof val === 'boolean') {
                prev.push(`${prefix}=${val.toString().toUpperCase()}`);
                return prev;
            }

            if (['number', 'string'].includes(typeof val)) {
                prev.push(`${prefix}=${encodeURIComponent(val)}`);
                return prev;
            }

            prev.push(
                Object.keys(val).reduce(reducer(val, prefix), []).join('&')
            );
            return prev;
        };
    }

    return Object.keys(object).reduce(reducer(object), []).join('&');
};

export const triggerNativeEvent = (event, target, eventOpts = {} ) => {
    target = target || window;
    if (typeof (Event) === 'function') {
        // modern browsers
        target.dispatchEvent(new Event(event, { bubbles: true, cancelable: false, ...eventOpts }));
    } else {
        // for IE and other old browsers
        // causes deprecation warning on modern browsers
        const e = target.document.createEvent('UIEvents');
        const { bubbles = true, cancelable = false, detail = 0 } = eventOpts;
        e.initUIEvent(event, bubbles, cancelable, target, detail);
        target.dispatchEvent(e);
    }
};

export const removeHashbang = () => {
    window.location.hash = '';
    if (window.history && window.history.replaceState) {
        window.history.replaceState(null, document.title, `${window.location.pathname}${window.location.search}`);
    }
};

export const triggerSearchModal = query => {
    let url = Config.get('searchUrl');
    if (query) {
        url += `?q=${query}`;
    }
    openAjaxModal(url, {
        animate: false,
        clickOutsideToClose: true
    });
};

const i18n = Config.get('i18n');
export const t = (key, vars = {}) => {
    let value = get(i18n, key, key);
    Object.keys(vars).forEach(name => {
        const pattern = `{${name}}`;
        value = value.replace(new RegExp(pattern, 'g'), vars[name]);
    });
    return value;
};

/**
 * Convert newlines to <br/>
 *
 * @param str
 */
export const nl2br = str => (str ? str.replace(/(?:\r\n|\r|\n|\\r\\n|\\r|\\n)/g, '<br />') : null);

/**
 * @returns {boolean}
 */
export const isTouch = () => !document.documentElement.classList.contains('using-mouse');

/**
 * @param y
 * @returns {gsap.core.Tween}
 */
export const animatedScroll = (y, vars = {}, offsetY = 0) => {
    let scrollMarginTop = 0;
    if (y instanceof HTMLElement) {
        scrollMarginTop = parseInt((window.getComputedStyle(y).scrollMarginTop || '0').replace('px', ''), 10) || 0;
    }
    let onComplete;
    if (vars.hasOwnProperty('onComplete')) {
        onComplete = vars.onComplete;
        delete vars.onComplete;
    }
    return gsap.to(window, {
        scrollTo: {
            y,
            offsetY: Math.max(0, offsetY + scrollMarginTop),
            autoKill: !isTouch(),
            onAutoKill() {
                Dispatch.emit(PROGRAMMATIC_SCROLL_END);
            }
        },
        duration: 1,
        ease: 'Power2.easeInOut',
        onStart() {
            Dispatch.emit(PROGRAMMATIC_SCROLL_START);
        },
        onComplete() {
            Dispatch.emit(PROGRAMMATIC_SCROLL_END);
            if (y instanceof HTMLElement && (y.tagName === 'A' || y.tagName === 'BUTTON')) {
                try {
                    y.focus();
                } catch (error) {
                    // Dont care
                }
            }
            if (onComplete) {
                onComplete();
            }
        },
        ...vars
    });
};
