import gsap from 'gsap';

import $ from '../core/Dom';
import Components from '../core/Components';
import superagent from '../core/request';
import Viewport from '../core/Viewport';

import { lockScroll, releaseScroll } from '../lib/helpers';

export default (el, {
    packageCode,
    bookingUrlTemplate
}) => {

    const $el = $(el);

    const itineraryModal = $el.find('[data-itinerary-modal]')
        .get(0);
    const itineraryModalTrigger = $el.find('button[data-itinerary-modal-trigger]')
        .get(0);

    let itineraryModalIsOpen = false;
    let request;

    const getProductCode = () => $el.find('select[id$="product"]')
        .get(0).value;

    const getItemCode = () => $el.find(`[data-product-items="${getProductCode()}"] select[id$="item"]`)
        .get(0).value;

    const getSelectedItemOption = () => $el.find(`[data-product-items="${getProductCode()}"] select[id$="item"] option[value="${getItemCode()}"]`)
        .get(0);

    const updatePriceAndUrl = () => {

        const productCode = getProductCode();
        const selectedItem = getSelectedItemOption();

        if (selectedItem) {
            // Get item price and category from selected item
            const {
                itemprice: price,
                category: superCategory
            } = selectedItem.dataset;
            // Update price=
            if (price) {
                $el.find('[data-price]')
                    .text(price);
            }
            // Update booking URL
            const url = bookingUrlTemplate
                .replace('{superCategory}', superCategory)
                .replace('{productCode}', productCode);
            $el.find('[data-booking-btn]')
                .attr({ href: url });
        }

        // Also update relevant panels
        $el.find('[data-panel="cabins"] [data-product]')
            .each(node => {
                node.hidden = node.dataset.product !== productCode;
            });
    };

    const onProductSelectChange = e => {
        const { value: productCode } = e.triggerTarget;
        const items = $el.find(`[data-product-items="${productCode}"]`)
            .get(0);
        if (!items || !items.hidden) {
            return;
        }
        $el.find('[data-product-items]')
            .each(node => {
                node.hidden = true;
            });
        items.hidden = false;
        updatePriceAndUrl();
    };

    const onItemSelectChange = e => {
        const { selectedIndex } = e.triggerTarget;
        const { text: selectedSuperCategory = '' } = e.triggerTarget.options[selectedIndex];
        if (!selectedSuperCategory) {
            return;
        }
        $el.find('select[id$="item"]')
            .each(itemSelect => {
                const selectedOption = getSelectedItemOption();
                if (selectedOption) {
                    itemSelect.value = selectedOption.value;
                }
            });
        updatePriceAndUrl();
    };

    const initCabinsPanel = panel => {
        if (panel.hasAttribute('data-panel-loaded')) {
            Components.init(panel);
            return;
        }
        if (request) {
            request.abort();
        }
        const {
            cabinsFilter: cabinsTypeFilter,
            hidePrices
        } = panel.dataset;
        request = superagent.get('/actions/escape/default/get-cruise-package-card-cabins-html');
        request
            .query({
                packageCode,
                productCode: getProductCode(),
                cabinsTypeFilter,
                hidePrices
            })
            .then(({
                status,
                text
            }) => {
                if (status !== 200 || !text) {
                    throw new Error();
                }
                panel.setAttribute('data-panel-loaded', 'true');
                $(panel.firstElementChild)
                    .html(text);
                Components.init(panel);
            })
            .catch(error => {
                console.error(error);
            })
            .then(() => {
                request = null;
            });
    };

    const initCalendarPanel = panel => {
        if (panel.hasAttribute('data-panel-loaded')) {
            Components.init(panel);
            return;
        }
        if (request) {
            request.abort();
        }
        const productCodes = $el.find('select[id$="product"] option').get().map(option => option.value).join(',');
        const {
            cabinsFilter: cabinsTypeFilter,
            hideBookingLinks,
            hidePrices
        } = panel.dataset;
        request = superagent.get('/actions/escape/default/get-cruise-package-card-calendar-html');
        request
            .query({
                packageCode,
                productCodes,
                cabinsTypeFilter,
                hideBookingLinks,
                hidePrices
            })
            .then(({
                status,
                text
            }) => {
                if (status !== 200 || !text) {
                    throw new Error();
                }
                panel.setAttribute('data-panel-loaded', 'true');
                $(panel.firstElementChild)
                    .html(text);
                Components.init(panel);
            })
            .catch(error => {
                console.error(error);
            })
            .then(() => {
                request = null;
            });
    };

    const expandPanel = panel => {
        panel.hidden = false;
        $el.find(`button[aria-controls="${panel.id}"]`)
            .attr({ 'aria-expanded': 'true' });
        const { panel: panelType } = panel.dataset;
        if (request) {
            request.abort();
            request = null;
        }
        if (panelType === 'cabins') {
            initCabinsPanel(panel);
        } else if (panelType === 'calendar') {
            initCalendarPanel(panel);
        }
    };

    const collapsePanel = panel => {
        Components.destroy(panel);
        panel.hidden = true;
        $el.find(`button[aria-controls="${panel.id}"]`)
            .attr({ 'aria-expanded': 'false' });
    };

    const togglePanel = panelId => {
        const panel = $el.find(`[data-panel="${panelId}"]`)
            .get(0);
        if (!panel) {
            return;
        }
        $el.find(`[data-panel]:not([data-panel="${panelId}"]`)
            .each(collapsePanel);
        if (panel.hidden) {
            expandPanel(panel);
        } else {
            collapsePanel(panel);
        }
    };

    const onPanelTriggerClick = e => {
        const { panelTrigger: panelId } = e.triggerTarget.dataset;
        togglePanel(panelId);
    };

    const openItineraryModal = () => {
        if (!itineraryModal || itineraryModalIsOpen) {
            return;
        }
        itineraryModalIsOpen = true;
        itineraryModalTrigger.setAttribute('aria-expanded', 'true');
        gsap.killTweensOf(itineraryModal);
        const $contentDiv = $($(itineraryModal)
            .find('[aria-live="polite"]')
            .get(0).firstElementChild);
        itineraryModal.hidden = false;
        lockScroll(itineraryModal);
        Viewport.releaseTabbing();
        Viewport.lockTabbing(itineraryModal, $(itineraryModal)
            .find('button[data-closebtn]')
            .get(0) || itineraryModal);
        if (request) {
            request.abort();
        }
        gsap.fromTo(itineraryModal, { opacity: 0 }, {
            opacity: 1,
            duration: 0.3
        });
        const { hideBookingLinks } = itineraryModal.dataset;
        request = superagent.get('/actions/escape/default/get-cruise-product-itiniary-modal-html');
        request
            .query({
                packageCode,
                productCode: getProductCode(),
                hideBookingLinks
            })
            .then(({
                status,
                text
            }) => {
                if (status !== 200 || !text) {
                    throw new Error();
                }
                $contentDiv.html(text);
                Components.init(itineraryModal);
                gsap.fromTo($contentDiv.get(0), { opacity: 0 }, {
                    opacity: 1,
                    duration: 0.3
                });
            })
            .catch(error => {
                console.error(error);
            })
            .then(() => {
                request = null;
            });
    };

    const closeItineraryModal = () => {
        if (!itineraryModal || !itineraryModalIsOpen) {
            return;
        }
        if (request) {
            request.abort();
            request = null;
        }
        itineraryModalIsOpen = false;
        itineraryModalTrigger.setAttribute('aria-expanded', 'false');
        gsap.killTweensOf(itineraryModal);
        const $contentDiv = $($(itineraryModal)
            .find('[aria-live="polite"]')
            .get(0).firstElementChild);
        gsap.to(itineraryModal, {
            opacity: 0,
            duration: 0.3,
            onComplete() {
                releaseScroll();
                Viewport.releaseTabbing(itineraryModalTrigger);
                itineraryModal.hidden = true;
                Components.destroy(itineraryModal);
                $contentDiv.html('');
            }
        });
    };

    const toggleItineraryModal = () => {
        if (itineraryModalIsOpen) {
            closeItineraryModal();
        } else {
            openItineraryModal();
        }
    };

    const onItineraryModalTriggerClick = e => {
        toggleItineraryModal();
    };

    const onBodyKeyUp = e => {
        if (!itineraryModalIsOpen) {
            return;
        }
        const key = e.which || e.keyCode;
        if (key === 27) {
            closeItineraryModal();
        }
    };

    $el.on('change', 'select[id$="product"]', onProductSelectChange);
    $el.on('change', 'select[id$="item"]', onItemSelectChange);
    $el.on('click', 'button[data-panel-trigger]', onPanelTriggerClick);
    $el.on('click', 'button[data-itinerary-modal-trigger]', onItineraryModalTriggerClick);
    $el.on('click', '[data-itinerary-modal] button[data-closebtn]', onItineraryModalTriggerClick);
    $('body')
        .on('keyup', onBodyKeyUp);

    return {
        destroy() {
            $el.off('change click');
            $('body')
                .off('keyup', onBodyKeyUp);
            closeItineraryModal();
        }
    };

};
