import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import { loadFlickity } from '../lib/async-bundles';

export default el => {

    if (el.offsetParent === null) {
        return null;
    }

    const $el = $(el);
    const $tablist = $el.find('ul,[role="list"]').eq(0);

    if (!$tablist.length) {
        console.warn('No tablist found');
        return null;
    }

    const tabs = $tablist.find('a[href^="#"]').get();

    if (!tabs.length) {
        return null;
    }

    const nextTabBtn = $el.find('button[data-next]').get(0);
    const prevTabBtn = $el.find('button[data-prev').get(0);

    const intersectingTabs = new Set();

    let flickity;
    let flickityDirection = 'next';
    let prevFlickitySlideIndex = 0;
    let viewportW;
    let tabsObserver;

    const getTabIndex = tab => Array.prototype.indexOf.call(tabs, tab);

    const selectTab = (newTab, setFocus = true) => {
        if (!newTab || newTab.hasAttribute('aria-selected')) {
            return;
        }
        const oldTabs = $tablist.find('a[role="tab"][aria-selected]').get();
        if (setFocus) {
            newTab.focus();
        }
        $(newTab.parentNode).find('a').each(link => link.removeAttribute('tabIndex'));
        newTab.setAttribute('aria-selected', 'true');
        const newPanel = $(newTab.getAttribute('href')).get(0);
        if (newPanel) {
            newPanel.hidden = false;
        }
        if (oldTabs.length) {
            oldTabs.forEach(oldTab => {
                oldTab.removeAttribute('aria-selected');
                $(oldTab.parentNode).find('a').attr({ tabIndex: '-1' });
                const oldPanel = $(oldTab.getAttribute('href')).get(0);
                if (oldPanel) {
                    oldPanel.hidden = true;
                }
            });
        }
        el.dispatchEvent(new Event('tabchange'));
    };

    const onTabClick = e => {
        e.preventDefault();
        selectTab(e.triggerTarget);
    };

    const onTabKeydown = e => {
        const { triggerTarget: tab } = e;
        const index = getTabIndex(tab);
        if (e.which === 37) {
            const prevTab = tabs[index - 1] || null;
            if (prevTab) {
                selectTab(prevTab);
            }
        } else if (e.which === 39) {
            const nextTab = tabs[index + 1] || null;
            if (nextTab) {
                selectTab(nextTab);
            }
        } else if (e.which === 40) {
            const panel = $(tab.getAttribute('href'))
                .get(0);
            if (panel) {
                panel.focus();
            }
        }
    };

    const killScrollable = () => {
        if (!flickity) {
            return;
        }
        flickity.destroy();
        flickity = null;
        $tablist
            .css({
                width: '',
                height: ''
            });
        $tablist.find('a').off('focusin');
    };

    const createScrollable = () => {

        prevFlickitySlideIndex = 0;

        loadFlickity(Flickity => {

            const { width, height } = $tablist.get(0).parentNode.getBoundingClientRect();
            $tablist.css({ width, height });

            flickity = new Flickity($tablist.get(0), {
                contain: true,
                dragThreshold: 15,
                cellAlign: 'left',
                prevNextButtons: false,
                groupCells: true,
                pageDots: false,
                freeScroll: false,
                freeScrollFriction: 0.045,
                percentPosition: false,
                accessibility: false,
                setGallerySize: false,
                adaptiveHeight: false,
                resize: false,
                on: {
                    dragStart() {
                        document.ontouchmove = e => e.preventDefault();
                        $tablist.css({ pointerEvents: 'none' });
                    },
                    dragEnd() {
                        document.ontouchmove = () => true;
                        $tablist.css({ pointerEvents: '' });
                    },
                    select() {
                        this.cells.forEach(({ element }) => {
                            element.removeAttribute('aria-hidden');
                        });
                        if (nextTabBtn && prevTabBtn) {
                            const { selectedIndex, slides } = this;
                            if (selectedIndex <= 0) {
                                prevTabBtn.setAttribute('aria-disabled', 'true');
                            } else {
                                prevTabBtn.removeAttribute('aria-disabled');
                            }
                            if (selectedIndex >= slides.length - 1) {
                                nextTabBtn.setAttribute('aria-disabled', 'true');
                            } else {
                                nextTabBtn.removeAttribute('aria-disabled');
                            }
                        }
                        if (this.selectedIndex > prevFlickitySlideIndex) {
                            flickityDirection = 'next';
                        } else {
                            flickityDirection = 'prev';
                        }
                        prevFlickitySlideIndex = this.selectedIndex;
                    }
                }
            });

            $tablist.find('.flickity-viewport,.flickity-slider').css({
                position: 'relative',
                display: 'flex'
            });

            $tablist.find('a').on('focusin', e => {
                const tab = $(e.delegateTarget).parent('[role="presentation"]').find('[role="tab"]').get(0);
                if (!tab) {
                    return;
                }
                const index = getTabIndex(tab);
                $tablist.get(0).parentNode.scrollLeft = 0;
                if (!intersectingTabs.has(tab)) {
                    flickity.selectCell(index, false);
                }
            });
        });

    };

    const onResize = () => {
        if (Viewport.width === viewportW) {
            return;
        }
        killScrollable();
        viewportW = Viewport.width;
        createScrollable();
    };

    // Create tablist
    $tablist.attr('role', 'tablist');

    tabs.forEach((tab, index) => {
        tab.setAttribute('role', 'tab');
        const panelId = tab.getAttribute('href').slice(1);
        if (!tab.getAttribute('id')) {
            tab.setAttribute('id', `tab-${panelId}`);
        }
        tab.parentNode.setAttribute('role', 'presentation');
        $(tab.parentNode).find('a').attr({ tabIndex: '-1' });
        const panel = $(`#${panelId}`).get(0);
        if (panel) {
            panel.setAttribute('role', 'tabpanel');
            panel.setAttribute('tabindex', '-1');
            if (!panel.getAttribute('aria-labelledby')) {
                panel.setAttribute('aria-labelledby', `tab-${panel.id}`);
            }
            panel.hidden = true;
        }
    });

    const onPrevTabBtnClick = () => {
        if (!flickity) {
            return;
        }
        flickity.previous();
    };

    const onNextTabBtnClick = () => {
        if (!flickity) {
            return;
        }
        flickity.next();
    };

    onResize();

    Viewport.on('resize', onResize);

    $tablist.on('click', 'a[role="tab"]', onTabClick);
    $tablist.on('keydown', 'a[role="tab"]', onTabKeydown);

    if (nextTabBtn && prevTabBtn) {
        $el.on('click', 'button[data-prev]', onPrevTabBtnClick);
        $el.on('click', 'button[data-next]', onNextTabBtnClick);
    }

    selectTab($tablist.find('[role="tab"][aria-selected]').get(0) || tabs[0], false);

    let intersectionTimer;

    const onTabObserver = entries => {
        entries.forEach(({ isIntersecting, target }) => {
            if (isIntersecting) {
                intersectingTabs.add(target);
            } else {
                intersectingTabs.delete(target);
            }
        });
        if (intersectionTimer) {
            clearTimeout(intersectionTimer);
        }
        intersectionTimer = setTimeout(() => {
            clearTimeout(intersectionTimer);
            intersectionTimer = null;
            const selectedTab = $tablist.find('[role="tab"][aria-selected]').get(0);
            if (intersectingTabs.has(selectedTab)) {
                return;
            }
            const intersectingTabsSorted = Array.from(intersectingTabs.values()).sort((a, b) => (getTabIndex(a) + 1) - (getTabIndex(b) + 1));
            if (flickityDirection === 'next') {
                selectTab(intersectingTabsSorted[0]);
            } else {
                selectTab(intersectingTabsSorted[intersectingTabsSorted.length - 1]);
            }
        }, 100);
    };

    tabsObserver = new IntersectionObserver(onTabObserver, {
        root: $tablist.get(0),
        threshold: 1
    });

    tabs.forEach(tab => {
        tabsObserver.observe(tab);
    });

    return {
        destroy() {
            $tablist.off('click keydown');
            $el.off('click');
            if (tabsObserver) {
                tabsObserver.disconnect();
                tabsObserver = null;
            }
            killScrollable();
        }
    };
};
