import gsap from 'gsap';
import Viewport from '../core/Viewport';
import Components from '../core/Components';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';

import * as events from '../lib/events';

let focusedElement;

export default class Modal {

    constructor(opts) {
        this.onKeyUp = this.onKeyUp.bind(this);
        this.onBodyClick = this.onBodyClick.bind(this);
        this.onResize = this.onResize.bind(this);
        this.opts = {
            className: '',
            animate: 'vertical',
            autoFocusInput: true,
            clickOutsideToClose: false,
            escToClose: true,
            ...(opts || {})
        };
        this.id = this.opts.id || `modal-${new Date().getTime()}`;
        console.log('created modal', this.opts);
    }

    unsetOpts() {
        if (!this.opts || !this.isOpen) {
            return;
        }
        const { className } = this.opts || {};
        if (className) {
            this.$modal.removeClass(className);
        }
        this.opts = null;
    }

    setOpts(opts) {

        if (!opts || !this.isOpen) {
            return;
        }

        if (this.opts && this.isOpen) {
            this.unsetOpts();
        }

        this.opts = opts || null;

        if (!this.opts) {
            return;
        }

        const { className } = this.opts;
        if (className) {
            this.$modal.addClass(className);
        }
    }

    addHtml(html) {

        if (!this.isOpen) {
            console.warn('Tried to add content to modal before opening');
            return;
        }

        const $content = this.$modal.find('[data-modal-content]');

        Components.destroy($content);

        $content.html(html);
        try {
            $content.find('h1,h2,h3,h4,h5').get(0).id = `${this.id}-title`;
        } catch (error) {}

        Components.init($content.get(0));

        let elToFocus = null;

        if (this.opts.autoFocusInput) {
            elToFocus = $content.find('input:not([type="hidden"]),select,textarea').get(0);
        }

        elToFocus = elToFocus || this.$modal.find('[data-modal-close]').get(0);

        if (elToFocus) {
            try {
                elToFocus.focus();
            } catch (error) {
                console.error(error);
            }
        }

        const inner = this.$modal.find('[data-modal-inner]').get(0);

        const { animate } = this.opts || {};

        const timeline = gsap.timeline({ paused: true })
            .fromTo(inner, {
                opacity: 0
            }, {
                opacity: 1,
                duration: 0.3
            }, 'in');

        if (animate === 'horizontal') {
            const stageW = this.$modal.width();
            const pixelsPerSecond = 900;
            const time = stageW / pixelsPerSecond;
            gsap.set(inner, {
                right: 0,
                left: 'auto',
                margin: '0 0 0 auto'
            });
            timeline
                .fromTo(inner, {
                    xPercent: 100
                }, {
                    xPercent: 0,
                    duration: time,
                    ease: 'Quint.easeOut'
                }, 'in');
        }
        else if (animate === 'vertical') {
            timeline.fromTo(inner, {
                yPercent: 2
            }, {
                yPercent: 0,
                duration: 0.5,
                ease: 'Cubic.easeOut'
            }, 'in');
        }

        timeline.play();

        Dispatch.emit(events.MODAL_UPDATED, {
            modal: this
        });

    }

    open() {

        if (this.isOpen) {
            return;
        }

        this.isOpen = true;

        // Create modal
        const $modal = this.getNode(this.id);

        $modal.css({
            position: 'fixed',
            width: '100%',
            height: '100vh',
            top: 0,
            left: 0
        });

        // Add modal to DOM
        const $body = $('body');
        $body.append($modal);

        this.$modal = $(`#${this.id}`);
        this.$modal.on('click', '[data-modal-close]', this.onModalCloseClick.bind(this));
        this.$modal.find('[data-modal-inner]')
            .css({
                opacity: 0,
                left: 0
            });

        $body
            .on('keyup', this.onKeyUp)
            .on('click', this.onBodyClick);

        focusedElement = document.activeElement || null;

        const modal = this.$modal.get(0);
        //lockScroll(modal);

        Viewport.releaseTabbing();
        Viewport.lockTabbing(modal, modal);

        Viewport.on('resize', this.onResize);
        this.onResize();

        this.$modal.attr('tabindex', -1).get(0).focus();

        gsap.fromTo(this.$modal.get(0), 0.3, {
            opacity: 0
        }, {
            opacity: 1
        });

        Dispatch.emit(events.MODAL_OPENED, {
            modal: this
        });

        this.setOpts(this.opts);

    }

    get() {
        return this.$modal.get(0);
    }

    close() {

        if (!this.isOpen) {
            return;
        }

        this.isOpen = false;

        const timeline = gsap.timeline({
            onComplete: () => {
                this.destroy();
            }
        });

        const { animate } = this.opts || {};
        const modal = this.$modal.get(0);
        const inner = this.$modal.find('[data-modal-inner]').get(0);

        if (animate === 'horizontal') {
            const $content = this.$modal.find('[data-modal-content]');
            const $innerContent = $content.find('*');
            const stageW = $innerContent.length ? $innerContent.width() : $content.width();
            const pixelsPerSecond = 1600;
            const time = stageW / pixelsPerSecond;
            timeline
                .to(inner, time, {
                    xPercent: 100,
                    ease: 'Quint.easeIn'
                }, 0)
                .to(modal, 0.3, {
                    opacity: 0
                }, time * 0.75);
        } else {
            timeline
                .to(inner, 0.3, {
                    opacity: 0
                }, 0)
                .to(modal, 0.3, {
                    opacity: 0
                }, 0.15);
        }

        timeline.play();
    }


    destroy() {

        if (!this.$modal) {
            return;
        }

        Viewport.off('resize', this.onResize);
        Viewport.releaseTabbing();
        if (focusedElement) {
            try {
                focusedElement.focus();
            } catch (error) {
                console.warn(error);
            }
        }
        focusedElement = null;
        //releaseScroll();

        $('html').removeClass('no-outline').addClass('outline');

        $('body')
            .off('keyup', this.onKeyUp)
            .off('click', this.onBodyClick);

        this.$modal
            .off('click')
            .off('touchmove')
            .remove();

        const $content = this.$modal.find('[data-modal-content]');
        Components.destroy($content);

        delete this.$modal;
        delete this.isOpen;

        Dispatch.emit(events.MODAL_CLOSED, {
            modal: this
        });

    }

    onModalCloseClick(e) {
        e.preventDefault();
        this.close();
    }

    onBodyClick(e) {
        if (!this.opts.clickOutsideToClose) {
            return true;
        }
        const target = e.target;
        if (target.dataset.hasOwnProperty('searchbtn') || $(target).parent('[data-searchbtn]').length) {
            return false;
        }
        const $modalContent = this.$modal.find('[data-modal-content]');
        if (target !== $modalContent.get(0) && $modalContent.contains(target)) {
            return false;
        }
        this.close();
        return true;
    }

    onKeyUp(e) {
        if (!this.opts.escToClose) {
            return true;
        }
        const key = e.which || e.keyCode;
        if (key === 27) {
            this.close();
        }
    }

    onResize() {

    }

    getNode(id) {
        return $(`
            <div id="${id}" class="modal flex flex-col text-white" role="dialog" aria-labelledby="${id}-title" aria-describedby="${id}-content">
                <div class="absolute full scrollable scrollbar-hidden z-2">
                    <div class="w-full min-h-scroll m-auto flex-auto relative z-3 flex justify-center items-center" data-modal-inner>
                        <div id="${id}-content" class="modal__content w-full min-h-screen flex-auto flex flex-col justify-center items-center relative" data-modal-content></div>
                     </div>
                     <div class="modal__spinner spinner fixed center z-2">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="60px" height="60px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" class="block stroke-current">
                            <circle cx="50" cy="50" fill="none" stroke-width="6" r="35" stroke-dasharray="164.93361431346415 56.97787143782138" transform="rotate(47.4692 50 50)">
                                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                            </circle>
                        </svg>
                     </div>
                </div>
                 <div class="fixed full z-0 bg-primary-dark opacity-75"></div>
            </div>
        `);
    }

}
