const loadMap = require('bundle-loader?lazy&name=map!../modules/Map');

export default (el, props) => {

    let map;

    const init = () => {
        loadMap(({ default: Map }) => {
            map = Map(el, props);
            map.init();
        });
    };

    const destroy = () => {
        if (map) {
            map.destroy();
        }
    };

    return {
        init,
        destroy
    };
};
