import $ from '../core/Dom';
import { triggerNativeEvent } from "../lib/helpers";

export default el => {

    const $el = $(el);
    const elHeight = $el.height();
    let iterations = 0;
    let poller;

    const clearPoller = () => {
        if (!poller) {
            return;
        }
        clearInterval(poller);
        poller = null;
    };

    const checkHeight = () => {
        const height = $el.height();
        if (height === elHeight) {
            return;
        }
        triggerNativeEvent('resize');
        clearPoller();
    };

    const init = () => {
        poller = setInterval(() => {
            checkHeight();
            iterations++;
            if (iterations >= 20) {
                clearPoller();
            }
        }, 250);
    };

    const destroy = () => {
        clearPoller();
    };

    return {
        init,
        destroy
    };
};
