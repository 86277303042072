import gsap from 'gsap';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Viewport from '../core/Viewport';

import { ALERT_CLOSED, ALERT_CLOSING } from '../lib/events';

export default (el, props) => {

    const $el = $(el);
    const $inner = $el.find('[data-inner]');
    const { cookieName, cookieValue, cookieDuration } = props;

    let isClosed = false;

    const onCloseBtnClick = () => {
        if (isClosed) {
            return;
        }
        isClosed = true;
        window.vrsg.setCookie(cookieName, cookieValue, cookieDuration);
        gsap.timeline({
            onUpdate: () => {
                Dispatch.emit(ALERT_CLOSING);
            },
            onComplete: () => {
                el.hidden = true;
                Dispatch.emit(ALERT_CLOSED);
            }
        })
            .to(el, {
                height: 0,
                duration: 0.5
            }, 0)
            .to($inner.get(0), {
                yPercent: -100,
                duration: 0.5
            }, 0);
    };

    const onResize = () => {
        if (isClosed) {
            return;
        }
        $el.height($inner.height());
    };

    const init = () => {
        $el.on('click', '[data-closebtn]', onCloseBtnClick);
        onResize();
        $inner.css({
            position: 'fixed',
            top: 0, left: 0
        });
        Viewport.on('resize', onResize);
    };

    const destroy = () => {
        $el.off('click');
        Viewport.off('resize', onResize);
    };

    return {
        init,
        destroy
    };
};
