import $ from '../core/Dom';
import superagent from '../core/request';
import Components from '../core/Components';

export default el => {

    const $el = $(el);
    const announcer = el.querySelector('[data-announcer]');

    let request;

    const onTabChange = () => {
        const activeTab = $el.find('[role="tabpanel"]:not([hidden])').get(0);
        if (!activeTab || activeTab.classList.contains('has-loaded')) {
            return;
        }
        const { key } = activeTab.dataset;
        if (!key) {
            return;
        }
        const tabAnnouncements = JSON.parse(activeTab.dataset.announcements);
        announcer.textContent = tabAnnouncements.loading;
        request = superagent.get('/actions/escape/default/get-calendar-widget-html');
        request
            .query({ key })
            .then(({ status, text }) => {
                if (status !== 200 || !text) {
                    throw new Error();
                }
                activeTab.innerHTML = text;
                activeTab.classList.add('has-loaded');
                Components.init(activeTab);
                announcer.textContent = tabAnnouncements.loaded;
            })
            .catch(error => {
                console.error(error);
            });
    };

    const init = () => {
        $el.find('[data-component="Tabs"]').on('tabchange', onTabChange);
    };

    const destroy = () => {
        $el.find('[data-component="Tabs"]').off('tabchange', onTabChange);
        if (request) {
            request.abort();
            request = null;
        }
    };

    return {
        init,
        destroy
    };

};
