import $ from '../core/Dom';

import { openAjaxModal } from "../modules/ModalController";

export default el => {

    const $el = $(el);

    const imageIds = $el.data('imageids').split(',');
    const { protocol, host, pathname } = window.location;
    const slideshowUrl = `${protocol}//${host}${pathname}/gallery?images=${imageIds.join(',')}`;

    const onImageClick = e => {
        e.preventDefault();
        const image = e.triggerTarget;
        const { id } = image.dataset;
        const url = `${slideshowUrl}&start=${id}`;
        openAjaxModal(url, {
            animate: false
        });
    };

    const init = () => {
        $el.on('click', 'a[data-id]', onImageClick);
    };

    const destroy = () => {
        $el.off('click');
    };

    return {
        init,
        destroy
    };

};
