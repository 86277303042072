/*
 DESCRIPTION: Facade module for PubSub implementation

 USAGE:
 // Import the Dispatch module
 import Dispatch from '@vaersaagod/tools/Dispatch';

 // Subscribe to event
 //  If last argument is true, the handler is called immediately if the event
 has already been published somewhere else in the application

 Dispatch.subscribe('some.key', (key, data) => {
 // Do something
 }, true || false);

 // Unsubscribe from event
 Dispatch.unsubscribe('some.key', subscription-reference || handler || nothing);

 // Publish an event
 Dispatch.publish('some.key', optionalData);

 */

import PubSub from 'pubsub-js';

let eventHistory = {};

let subscriptions = {};

const isToken = value => typeof value === 'string' && value.indexOf('uid_') > -1;

const isHandler = value => typeof value === 'function';

const addSubscription = (key, handler) => {

    let doAdd = true;

    // Don't add existing subscriptions
    Object.keys(subscriptions).forEach(token => {
        const subscription = subscriptions[token];
        doAdd = subscription.key !== key || subscription.handler !== handler;
    });

    if (doAdd) {
        const token = PubSub.subscribe(key, handler);
        subscriptions[token] = {
            token,
            key,
            handler
        };
    }

};

const removeSubscription = (keyTokenOrHandler, optionalHandler) => {

    let status = false;

    if (isToken(keyTokenOrHandler)) {
        delete subscriptions[keyTokenOrHandler];
        return PubSub.unsubscribe(keyTokenOrHandler);
    }

    Object.keys(subscriptions).forEach(token => {

        const subscription = subscriptions[token];
        let doRemove = false;

        if (isHandler(keyTokenOrHandler) && subscription.handler === keyTokenOrHandler) {
            doRemove = true;
        } else if (subscription.key === keyTokenOrHandler) {
            doRemove = true;
            if (isHandler(optionalHandler) && subscription.handler !== optionalHandler) {
                doRemove = false;
            }
        } else if (!keyTokenOrHandler) {
            doRemove = true;
            if (isHandler(optionalHandler) && subscription.handler !== optionalHandler) {
                doRemove = false;
            }
        }

        if (doRemove) {
            delete subscriptions[subscription.token];
            status = PubSub.unsubscribe(subscription.token);
        }

    });

    return status;

};

export default {

    on: (key, handler, publishFromHistory) => {
        if (publishFromHistory && typeof eventHistory[key] !== 'undefined') {
            handler(key, eventHistory[key]);
        }
        return addSubscription(key, handler);
    },

    off: (keyTokenOrHandler, optionalHandler) => {
        return removeSubscription(keyTokenOrHandler, optionalHandler);
    },

    emit: (key, data) => {
        eventHistory[key] = data || null;
        return PubSub.publishSync(key, data);
    },

    destroy: () => {
        removeSubscription(subscriptions);
        subscriptions = {};
        eventHistory = {};
        PubSub.clearAllSubscriptions();
    }

};
