import findKey from 'lodash/findKey';
import Config from '../core/Config';
import request from '../core/request';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';

import Modal from './Modal';

import * as events from '../lib/events';

let modal;
let currentRequest;

const killRequest = () => {
    if (currentRequest) {
        currentRequest.abort();
        currentRequest = null;
    }
};

const onModalClosed = () => {
    if (modal) {
        modal = null;
    }
    Dispatch.off(events.MODAL_CLOSED, onModalClosed);
};

export const openModal = (html, opts) => {
    if (!modal) {
        modal = new Modal(opts);
        modal.open();
        if (html) {
            modal.addHtml(html);
        }
        Dispatch.on(events.MODAL_CLOSED, onModalClosed);
    } else if (html) {
        if (opts) {
            modal.setOpts(opts);
        }
        modal.addHtml(html);
    }

    return modal;
};

export const openIframeModal = (url, opts) => {
    killRequest();
    openModal(null, opts);
    $(modal.get()).removeClass('js-is-loading');
    modal.addHtml(`<iframe src="${url}" class="d-b pos-a u-fill"></iframe>`);
    return modal;
};

export const openAjaxModal = (url, opts) => {

    killRequest();
    openModal(null, opts);

    $(modal.get()).addClass('js-is-loading');

    currentRequest = request.get(url)
        .type('text/html')
        .end((error, res) => {
            currentRequest = null;
            const { status, text: html } = res;
            if (status !== 200 || !html) {
                console.error({ status }, res.statusText || '');
                return;
            }
            $(modal.get()).removeClass('js-is-loading');
            requestAnimationFrame(() => {
                modal.addHtml(html);
            });
        });

    return modal;
};

export const openBookingModal = href => {
    modal = openIframeModal(href, {
        animate: 'horizontal'
    });
    $(modal.get()).find('[data-modal-inner]').css({
        maxWidth: '1080px'
    }).append(`
        <button class="d-f ai-c fs-18 u-hit flex-00 pos-a u-top" data-modal-close aria-label="${Config.get('i18n.labelClose')}" style="top:36px;right:36px;">
            <span class="d-ib flex-00 t-link--invert">${Config.get('i18n.labelClose')}</span>
            <span class="d-ib flex-00 pos-r ml-10">
                <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><g stroke-width="2.5" class="stroke-cc"><path d="M10 9.75L1.838 1.364 10 9.75l8.162-8.388L10 9.75zm0 0l8.647 8.887L10 9.751l-8.647 8.886L10 9.751z"/></g></g></svg>
            </span>
        </button>
    `);
    return modal;
};

export const init = () => {

    const siteUrl = Config.get('siteUrl').replace(/(^\w+:|^)\/\//, '//');
    const modalTriggers = Config.get('modalTriggers') || {};

    $('body').on('click', 'a', e => {

        const { triggerTarget: target } = e;

        if (target.getAttribute('data-noajax')) {
            return;
        }

        const href = target.getAttribute('href');

        if (!href) {
            return;
        }

        const url = target.getAttribute('href').replace(/(^\w+:|^)\/\//, '//').replace(siteUrl, '');
        const key = findKey(modalTriggers, item => item === url);

        if (!key) {
            return;
        }

        e.preventDefault();
        openAjaxModal(`/${url}`, {
            key
        });

    });

};
