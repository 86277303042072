import $ from '../core/Dom';
import Viewport from '../core/Viewport';

const loadFlickity = require('bundle-loader?lazy&name=flickity!flickity');

const SLIDER_SELECTOR = '[data-slider]';
const SLIDE_SELECTOR = '[data-slide]';
const NEXTBTN_SELECTOR = '[data-next]';
const PREVBTN_SELECTOR = '[data-prev]';

export default el => {

    const $el = $(el);
    const $slider = $el.find(SLIDER_SELECTOR);
    const $slides = $el.find(SLIDE_SELECTOR);

    let Flickity;
    let slider;
    let stageW = Viewport.width;

    let selectedIndex = 0;
    let sizePoller = null;

    const slideTextHeight = $slides.find('[data-slidetext]').height();

    const onSlideChange = () => {
        selectedIndex = slider.selectedIndex;
    };

    const destroyFlickity = () => {
        if (!slider) {
            return;
        }
        slider.off('change');
        slider.off('dragStart');
        slider.off('dragEnd');
        slider.destroy();
        slider = null;
        $slider.attr('style', '');
        $slides.attr('style', '');
        $el.removeClass('js-has-flickity');
    };

    const initFlickity = () => {

        destroyFlickity();

        $el.addClass('js-has-flickity');

        $slider.css({
            width: `${$slider.width()}px`,
            height: `${$slider.height()}px`
        });

        const slideWidth = $slides.width();

        $slides.css({
            top: 0,
            position: 'absolute'
        })
            .get()
            .forEach((item, index) => {
                $(item)
                    .css({
                        left: `${slideWidth * index}px`
                    });
            });

        slider = new Flickity($slider.get(0), {
            pageDots: false,
            prevNextButtons: false,
            setGallerySize: false,
            resize: false,
            wrapAround: true,
            initialIndex: selectedIndex,
            dragThreshold: 15
        });

        slider.on('change', onSlideChange);

        slider.on('dragStart', () => {
            document.ontouchmove = e => e.preventDefault();
        });

        slider.on('dragEnd', () => {
            document.ontouchmove = () => true;
        });
    };

    const onResize = () => {
        const vw = Viewport.width;
        if (!slider || vw === stageW) {
            return;
        }
        stageW = vw;
        initFlickity();
    };

    const onNextBtnClick = e => {
        e.preventDefault();
        if (!slider) {
            return;
        }
        slider.next();
    };

    const onPrevBtnClick = e => {
        e.preventDefault();
        if (!slider) {
            return;
        }
        slider.previous();
    };

    // The webfont loads slowly and affects the height of the slides
    // This is a hotfix to make sure that Flickity re-inits if the slide text height changes
    const checkSize = () => {
        if (!slider || !slideTextHeight) {
            return;
        }
        const newSlideTextHeight = $slides.find('[data-slidetext]').height();
        if (newSlideTextHeight !== slideTextHeight) {
            clearInterval(sizePoller);
            sizePoller = null;
            initFlickity();
        }
    };

    const init = () => {

        loadFlickity(flickity => {
            Flickity = flickity;
            initFlickity();
            Viewport.on('resize', onResize);
            sizePoller = setInterval(() => {
                checkSize();
            }, 250);
        });
        $el
            .on('click', NEXTBTN_SELECTOR, onNextBtnClick)
            .on('click', PREVBTN_SELECTOR, onPrevBtnClick);
    };

    const destroy = () => {
        if (sizePoller) {
            clearInterval(sizePoller);
            sizePoller = null;
        }
        if (slider) {
            destroyFlickity();
            Viewport.off('resize', onResize);
        }
        $el.off('click');
    };

    return {
        init,
        destroy
    };

};
