import $ from '../core/Dom';
import Viewport from '../core/Viewport';

import { loadFlickity } from '../lib/async-bundles';

const SLIDER_SELECTOR = '[data-slider]';
const NAVBTN_SELECTOR = '[data-navbtn]';

export default el => {

    const $el = $(el);
    const $slider = $el.find(SLIDER_SELECTOR);

    let $slides;

    let Flickity = null;
    let slideshow = null;

    const isSmall = () => ['m', 'mp', 'l', 'lp', 'xl'].indexOf(Viewport.breakpoint.name) === -1;

    const destroySlideshow = () => {
        if (!slideshow) {
            return;
        }
        slideshow.off('select');
        slideshow.destroy();
        slideshow = null;
    };

    const onSlideSelect = () => {
        const { selectedIndex } = slideshow;
        const $slide = $($slides.get(selectedIndex));
        const slideId = $slide.attr('id');
        $el.find(`a[href^="#highlight-"]:not([href="#${slideId}"]`).removeClass('is-active');
        $el.find(`a[href="#${slideId}"]`).addClass('is-active');
        $el.find(`${NAVBTN_SELECTOR}.is-active`).removeClass('is-active');
        $($el.find(NAVBTN_SELECTOR).get(selectedIndex)).addClass('is-active');
        const slideVideo = $slide.find('[data-video]').get(0);
        if (slideVideo) {
            slideVideo.hidden = false;
        }
    };

    const onSlideSettle = () => {
        const $videos = $slides.find('[data-video]');
        $videos.each(video => {
            video.hidden = !$(video).parent('.is-selected').get(0);
        });
    };

    const createSlideshow = () => {
        if (slideshow || isSmall()) {
            return;
        }
        if (Flickity === null) {
            Flickity = false;
            loadFlickity(flickity => {
                Flickity = flickity;
                createSlideshow();
            });
            return;
        }
        if (!Flickity) {
            return;
        }
        slideshow = new Flickity($slider.get(0), {
            pageDots: false,
            prevNextButtons: false,
            setGallerySize: false,
            wrapAround: true
        });
        slideshow.on('select', onSlideSelect);
        slideshow.on('settle', onSlideSettle);
        onSlideSelect();
    };

    const onSlideLinkClick = e => {
        if (!slideshow) {
            return true;
        }
        e.preventDefault();
        const { triggerTarget: target } = e;
        const { selectedIndex } = slideshow;
        const slideId = $(target).attr('href');
        const slide = $slider.find(slideId).get(0);
        const slideIndex = $slides.index(slide);
        if (slideIndex === selectedIndex) {
            slideshow.select(0);
        } else {
            slideshow.select(slideIndex);
        }
        return false;
    };

    const onNavBtnClick = e => {
        if (!slideshow) {
            return;
        }
        const { triggerTarget: target } = e;
        const slideIndex = parseInt($(target).data('index'), 10);
        slideshow.select(slideIndex);
    };

    const onBreakpoint = () => {
        if (isSmall()) {
            destroySlideshow();
        } else {
            createSlideshow();
        }
    };

    const init = () => {
        if (!$slider.get(0)) {
            return;
        }
        $slides = $($slider.get(0).children);
        $el
            .on('click', 'a[href^="#highlight-"]', onSlideLinkClick)
            .on('click', NAVBTN_SELECTOR, onNavBtnClick);
        Viewport.on('breakpoint', onBreakpoint);
        createSlideshow();
    };

    const destroy = () => {
        Viewport.off('breakpoint', onBreakpoint);
        $el.off('click');
    };

    return {
        init,
        destroy
    };
};
