import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import { getScrollbarWidth } from '../core/utils';

export default button => {

    const card = button.nextElementSibling;

    let isExpanded = false;

    const positionCard = () => {

        if (!isExpanded) {
            return;
        }

        // Try to center horizontally to button
        const {
            width: buttonWidth,
            height: buttonHeight,
            top: buttonTop
        } = button.getBoundingClientRect();
        const { left: buttonOffsetLeft } = $(button)
            .offset();
        const {
            width: cardWidth,
            height: cardHeight
        } = card.getBoundingClientRect();

        let cardX = Math.round((buttonWidth - cardWidth) * 0.5);

        if ((buttonOffsetLeft - 20) + cardX < 0) {
            cardX -= (buttonOffsetLeft - 20) + cardX;
        } else if (Viewport.width - (buttonOffsetLeft + cardX + cardWidth + 20 + getScrollbarWidth()) < 0) {
            cardX += Viewport.width - (buttonOffsetLeft + cardX + cardWidth + 20 + getScrollbarWidth());
        }

        const cardY = -(cardHeight + 20);
        gsap.set(card, {
            left: cardX,
            top: cardY
        });

    };

    const expand = () => {
        if (isExpanded) {
            return;
        }
        gsap.killTweensOf(card);
        isExpanded = true;
        button.setAttribute('aria-expanded', 'true');
        card.hidden = false;
        positionCard();
        const { top, height } = card.getBoundingClientRect();
        if (!Viewport.visible(card, -height)) {
            Viewport.scrollTo(Math.round(top - ((Viewport.height - height) * 0.5)));
        }
        gsap.fromTo(card, { opacity: 0 }, {
            opacity: 1,
            y: 30,
            duration: 0.3
        });
    };

    const collapse = () => {
        if (!isExpanded) {
            return;
        }
        gsap.killTweensOf(card);
        isExpanded = false;
        button.setAttribute('aria-expanded', 'false');
        gsap.to(card, {
            opacity: 0,
            duration: 0.3,
            y: 10,
            onComplete() {
                card.hidden = true;
            }
        });
    };

    const onClick = () => {
        if (!isExpanded) {
            expand();
        } else {
            collapse();
        }
    };

    const onBodyClick = e => {
        if (!isExpanded) {
            return;
        }
        const { target } = e;
        if (target !== button && !button.contains(target) && target !== card && !card.contains(target)) {
            collapse();
        }
    };

    $(button)
        .on('click', onClick);
    $('body')
        .on('click', onBodyClick);

    Viewport.on('resize', positionCard);

    return {
        destroy() {
            collapse();
            $(button)
                .off('click');
            $('body')
                .off('click', onBodyClick);
            Viewport.off('resize', positionCard);
        }
    };

};
