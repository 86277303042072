import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';

const EVENT_KEY = 'listbox:change';

export default el => {

    const $el = $(el);
    const $btn = $el.find('[aria-expanded]');
    const $listbox = $($btn.get(0).nextElementSibling);
    const $options = $listbox.find('[role="option"]');
    const $selectedLabel = $el.find('[data-selected]');
    const $input = $el.find('input[type="hidden"]');
    const numOptions = $options.length;
    const name = $input.attr('name');

    let value = $input.val();

    /**
     *
     * @returns {boolean}
     */
    const isExpanded = () => $btn.attr('aria-expanded') === 'true';

    /**
     *
     * @returns {unknown[]}
     */
    const getSelectedOption = () => $listbox.find('[aria-selected="true"]').get(0);

    /**
     *
     * @returns {number}
     */
    const getSelectedOptionIndex = () => Array.from($listbox.get(0).children).indexOf(getSelectedOption());

    /**
     *
     * @param option
     * @returns {boolean}
     */
    const isOptionSelected = option => option.getAttribute('aria-selected') === 'true';

    /**
     * Expand the listbox
     */
    const expand = () => {
        if (isExpanded()) {
            return;
        }
        $btn.attr('aria-expanded', 'true');
        $listbox.get(0).hidden = false;
        $listbox.get(0).focus();
    };

    /**
     * Collapse the listbox
     */
    const collapse = (focusOnButton = true) => {
        if (!isExpanded()) {
            return;
        }
        $btn.attr('aria-expanded', 'false');
        if (focusOnButton !== false) {
            $btn.focus();
        }
        $listbox.get(0).hidden = true;
        const selectedOption = getSelectedOption();
        $input.val(selectedOption.dataset.value);
        Dispatch.emit(EVENT_KEY, {
            id: el.id,
            name: name,
            value: $input.val()
        });
    };

    /**
     * Toggle the listbox (expand/collapse)
     */
    const toggle = () => {
        if (!isExpanded()) {
            expand();
        } else {
            collapse();
        }
    };

    /**
     * Select an option
     *
     * @param option
     */
    const selectOption = option => {
        expand();
        if (isOptionSelected(option)) {
            return;
        }
        getSelectedOption().removeAttribute('aria-selected');
        option.setAttribute('aria-selected', 'true');
        $listbox.attr('aria-activedescendant', option.id);
        $selectedLabel.text(option.textContent);
    };

    /**
     *
     */
    const selectNextOption = () => {
        let index = getSelectedOptionIndex() + 1;
        if (index > numOptions - 1) {
            index = 0;
        }
        selectOption($options.get(index));
    };

    const selectPrevOption = () => {
        let index = getSelectedOptionIndex() - 1;
        if (index < 0) {
            index = numOptions - 1;
        }
        selectOption($options.get(index));
    };

    const onToggleClick = () => {
        if ($btn.get(0).disabled) return;
        toggle();
    };

    const onToggleKeyDown = e => {
        if ($btn.get(0).disabled) return false;
        const key = e.which || e.keyCode || null;
        if (key !== 13) {
            return true;
        }
        e.preventDefault();
        e.stopPropagation();
    };

    const onOptionClick = e => {
        selectOption(e.triggerTarget);
        collapse();
    };

    const onKeyUp = e => {
        const key = e.which || e.keyCode || null;
        if (key === 40) { // Down arrow
            selectNextOption();
        } else if (key === 38) { // Up arrow
            selectPrevOption();
        } else if (key === 27) { // Escape key
            if (document.activeElement === $listbox.get(0)) {
                // Prevent modal from closing when trying to escape the listbox
                e.preventDefault();
                e.stopPropagation();
            }
            collapse();
        } else if (key === 13) { // Enter key
            const focusedElement = document.activeElement || null;
            if (focusedElement === $btn.get(0)) {
                toggle();
            } else if (focusedElement === $listbox.get(0)) {
                collapse();
            }
        }
    };

    const onBodyClickOrFocus = e => {
        if (!isExpanded()) {
            return;
        }
        const { target } = e;
        if (target === el || $el.contains(target)) {
            return;
        }
        collapse(false);
    };

    const init = () => {
        $el
            .on('click', 'button[aria-expanded]', onToggleClick)
            .on('click', '[role="option"]', onOptionClick)
            .on('keydown', 'button[aria-expanded]', onToggleKeyDown)
            .on('keyup', onKeyUp);
        $('body').on('click focusin', onBodyClickOrFocus);
    };

    const destroy = () => {
        $el.off('click keydown keyup');
        $('body').off('click focusin', onBodyClickOrFocus);
    };

    return {
        init,
        destroy
    };

};
