import $ from '../core/Dom';
import Components from '../core/Components';

const ENTRYTOGGLE_SELECTOR = '[data-entrytoggle]';
const TOGGLEALL_SELECTOR = '[data-showallbtn]';

export default el => {

    const $el = $(el);

    const collapse = $toggle => {
        $toggle.attr('aria-expanded', 'false');
        const $target = $toggle.next();
        $target.attr('tabIndex', -1).get(0).hidden = true;
        Components.destroy($target.get(0));
    };

    const expand = $toggle => {
        $toggle.attr('aria-expanded', 'true');
        const $target = $toggle.next();
        $target.attr('tabIndex', '').get(0).hidden = false;
        $target.find('[data-cmpnt]').each(component => {
            const cmpnt = component.getAttribute('data-cmpnt');
            const props = component.getAttribute('data-cmpnt-props');
            component.removeAttribute('data-cmpnt');
            component.removeAttribute('data-cmpnt-props');
            component.setAttribute('data-component', cmpnt);
            component.setAttribute('data-component-props', props);
        });
        Components.init($target.get(0));
    };

    const onEntryToggleClick = e => {
        e.preventDefault();
        const $toggle = $(e.triggerTarget);
        if ($toggle.attr('aria-expanded') !== 'true') {
            expand($toggle);
        } else {
            collapse($toggle);
        }
    };

    const onToggleAllSelectorClick = () => {
        const $toggles = $el.find(ENTRYTOGGLE_SELECTOR);
        const isAllExpanded = $el.find(`${ENTRYTOGGLE_SELECTOR}[aria-expanded="true"]`).length === $toggles.length;
        $toggles.each(toggle => {
            if (!isAllExpanded) {
                expand($(toggle));
            } else {
                collapse($(toggle));
            }
        });
    };

    const init = () => {
        // Set all hidden panels to tabIndex=-1
        $el.find(ENTRYTOGGLE_SELECTOR).each(toggle => {
            const $target = $(toggle).next();
            $target.attr('tabIndex', -1);
        });
        $el.on('click', ENTRYTOGGLE_SELECTOR, onEntryToggleClick);
        $el.on('click', TOGGLEALL_SELECTOR, onToggleAllSelectorClick);
    };

    const destroy = () => {
        $el.off('click');
    };

    return {
        init,
        destroy
    };
};
