import $ from '../core/Dom';
import Config from '../core/Config';
import { recursiveObjectToQueryString } from '../lib/helpers';

export default form => {

    const { cruiseSearchUrl } = Config.get();

    if (!cruiseSearchUrl) {
        return null;
    }

    const $form = $(form);
    const queryInput = $form.find('#cruise-widget-search').get(0);
    const dateInput = $form.find('#cruise-widget-date').get(0);
    const nightsInput = $form.find('#cruise-widget-number-of-nights').get(0);
    const destinationInput = $form.find('#cruise-widget-destination').get(0);

    let datePicker;

    const redirectToBooking = () => {
        let params = {};
        const query = queryInput.value;
        if (query) {
            params = {
                query
            };
        }
        const destination = destinationInput ? destinationInput.value : null;
        if (destination) {
            params = {
                ...params,
                facetFilter: {
                    'RegionHierarchical.Level1': {
                        [destination]: destination
                    }
                }
            };
        }
        const nights = (nightsInput.value || '').split(',').filter(value => parseInt(value, 10));
        if (nights.length === 2) {
            params = {
                ...params,
                numericalFilter: [{
                    name: 'NumberOfNights',
                    from: nights[0],
                    to: nights[1]
                }]
            };
        }
        if (datePicker && datePicker.selectedDates && datePicker.selectedDates.length === 2) {
            const dateFrom = datePicker.formatDate(datePicker.selectedDates[0], 'Y-m-d');
            const dateTo = datePicker.formatDate(datePicker.selectedDates[1], 'Y-m-d');
            if (dateFrom && dateTo) {
                params = {
                    ...params,
                    dateFilter: { dateFrom, dateTo }
                };
            }
        }
        let url = cruiseSearchUrl;
        const queryString = recursiveObjectToQueryString(params);
        if (queryString) {
            url = `${url}?${queryString}`;
        }
        window.location.href = url;
    };

    const onSubmit = e => {
        e.preventDefault();
        redirectToBooking();
    };

    const then = new Date().getTime();

    const createDatePicker = () => {
        // Poll for flatpickr
        if (!window.flatpickr) {
            if ((new Date().getTime()) - then < 3000) {
                requestAnimationFrame(createDatePicker);
            }
            return;
        }
        datePicker = window.flatpickr(dateInput, {
            minDate: dateInput.dataset.min,
            maxDate: dateInput.dataset.max,
            locale: dateInput.dataset.locale || null,
            dateFormat: 'd.m.Y',
            mode: 'range'
        });
    };

    createDatePicker();

    form.addEventListener('submit', onSubmit);

    return {
        destroy() {
            form.removeEventListener('submit', onSubmit);
            if (datePicker) {
                datePicker.destroy();
            }
        }
    };

};
