import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import { getScrollbarWidth } from '../core/utils';

export default button => {

    const popup = button.nextElementSibling;

    let isExpanded = false;

    const positionPopup = () => {

        if (!isExpanded) {
            return;
        }

        // Try to center horizontally to button
        const {
            width: buttonWidth,
            height: buttonHeight,
            top: buttonTop
        } = button.getBoundingClientRect();
        const { left: buttonOffsetLeft } = $(button)
            .offset();
        const {
            width: popupWidth,
            height: popupHeight
        } = popup.getBoundingClientRect();

        let popupX = Math.round((buttonWidth - popupWidth) * 0.5);

        if ((buttonOffsetLeft - 20) + popupX < 0) {
            popupX -= (buttonOffsetLeft - 20) + popupX;
        } else if (Viewport.width - (buttonOffsetLeft + popupX + popupWidth + 20 + getScrollbarWidth()) < 0) {
            popupX += Viewport.width - (buttonOffsetLeft + popupX + popupWidth + 20 + getScrollbarWidth());
        }

        const popupY = -(popupHeight + 20);
        gsap.set(popup, {
            left: popupX,
            top: popupY
        });

    };

    const expand = () => {
        if (isExpanded) {
            return;
        }
        gsap.killTweensOf(popup);
        isExpanded = true;
        button.setAttribute('aria-expanded', 'true');
        popup.hidden = false;
        positionPopup();
        const { top, height } = popup.getBoundingClientRect();
        if (!Viewport.visible(popup, -height)) {
            Viewport.scrollTo(Math.round(top - ((Viewport.height - height) * 0.5)));
        }
        gsap.timeline()
            .fromTo(popup, { opacity: 0 }, {
                opacity: 1,
                duration: 0.15
            }, 0)
            .fromTo(popup, {
                y: 30,
            }, {
                y: 0,
                duration: 0.3,
                ease: 'Back.easeOut'
            }, 0);
    };

    const collapse = () => {
        if (!isExpanded) {
            return;
        }
        gsap.killTweensOf(popup);
        isExpanded = false;
        button.setAttribute('aria-expanded', 'false');
        gsap.to(popup, {
            opacity: 0,
            duration: 0.15,
            y: 5,
            onComplete() {
                popup.hidden = true;
            }
        });
    };

    const onClick = () => {
        if (!isExpanded) {
            expand();
        } else {
            collapse();
        }
    };

    const onBodyClick = e => {
        if (!isExpanded) {
            return;
        }
        const { target } = e;
        if (target !== button && !button.contains(target) && target !== popup && !popup.contains(target)) {
            collapse();
        }
    };

    $(button)
        .on('click', onClick);
    $('body')
        .on('click', onBodyClick)
        .on('focusin', onBodyClick);

    Viewport.on('resize', positionPopup);

    return {
        destroy() {
            collapse();
            $(button)
                .off('click');
            $('body')
                .off('click', onBodyClick);
            Viewport.off('resize', positionPopup);
        }
    };

};
