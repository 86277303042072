import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import { loadFlickity } from "../lib/async-bundles";
import debounce from 'lodash/debounce';

const SLIDER_SELECTOR = '[data-slider]';
const SLIDE_SELECTOR = '[data-slide]';

const NEXTBTN_SELECTOR = '[data-next]';
const PREVBTN_SELECTOR = '[data-prev]';
const COUNTER_SELECTOR = '[data-current]';

export default (el, props) => {

    const $el = $(el);
    const $slider = $el.find(SLIDER_SELECTOR);
    const $slides = $el.find(SLIDE_SELECTOR);

    const { startAt } = props || {};

    let Flickity;
    let slideshow;

    let selectedIndex = 0;
    if (startAt) {
        const imageIds = $slides.map(slide => slide.dataset.id.toString());
        selectedIndex = imageIds.indexOf(startAt.toString()) > -1 ? imageIds.indexOf(startAt.toString()) : null;
    }

    const updateCounter = () => {
        $el.find(COUNTER_SELECTOR).text(selectedIndex + 1);
    };

    const onSlideChange = () => {
        selectedIndex = slideshow.selectedIndex;
        updateCounter();
    };

    const destroyFlickity = () => {
        if (!slideshow) {
            return;
        }
        slideshow.off('change');
        slideshow.destroy();
        slideshow = null;
        $slider.attr('style', '');
        $slides.attr('style', '');
        $el.removeClass('js-has-flickity');
    };

    const initFlickity = () => {

        destroyFlickity();

        $el.addClass('js-has-flickity');

        $slider.css({
            width: `${$slider.width()}px`,
            height: `${$slider.height()}px`
        });

        const slideWidth = $slides.width();
        const slideHeight = $slides.height();

        $slides.css({
            width: `${slideWidth}px`,
            height: `${slideHeight}px`,
            top: 0,
            position: 'absolute'
        })
            .get()
            .forEach((item, index) => {
                $(item)
                    .css({
                        left: `${slideWidth * index}px`
                    });
            });

        slideshow = new Flickity($slider.get(0), {
            pageDots: false,
            prevNextButtons: false,
            setGallerySize: false,
            resize: false,
            wrapAround: true,
            initialIndex: selectedIndex,
            accessibility: true
        });

        slideshow.on('change', onSlideChange);
        updateCounter();

        $el.addClass('js-initialised');

    };

    const scaleImages = () => {

        $slides
            .find(`${SLIDE_SELECTOR} img`)
            .each(img => {
                const $img = $(img);
                const $container = $img.parent();
                $img.css({ width: '', height: '' });
                $container.css({ width: '', height: '' });
                const imageHeight = $img.height();
                const availableHeight = $container.height();
                if (imageHeight > availableHeight) {
                    const imgWidth = $img.width();
                    const imgRatio = imgWidth / imageHeight;
                    $img.css({
                        width: '100%',
                        height: '100%'
                    })
                        .parent()
                        .css({
                            width: `${Math.round(availableHeight * imgRatio)}px`,
                            height: `${availableHeight}px`
                        });
                } else {
                    $container.css({
                        height: imageHeight
                    });
                }
            });
    };

    const scaleImagesDebounced = debounce(scaleImages, 10);

    const onResize = () => {
        initFlickity();
        scaleImagesDebounced();
    };

    const onNextBtnClick = e => {
        e.preventDefault();
        if (!slideshow) {
            return;
        }
        slideshow.next();
    };

    const onPrevBtnClick = e => {
        e.preventDefault();
        if (!slideshow) {
            return;
        }
        slideshow.previous();
    };

    const init = () => {
        loadFlickity(flickity => {
            Flickity = flickity;
            initFlickity();
        });
        $el
            .on('click', NEXTBTN_SELECTOR, onNextBtnClick)
            .on('click', PREVBTN_SELECTOR, onPrevBtnClick);
        Viewport.on('resize', onResize);
        requestAnimationFrame(scaleImages);
    };

    const destroy = () => {
        destroyFlickity();
        Viewport.off('resize', onResize);
        $el.off('click');
    };

    return {
        init,
        destroy
    };

};
