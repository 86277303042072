import $ from '../core/Dom';

import gsap from 'gsap';

export default el => {

    const $el = $(el);
    const $input = $el.find('input,textarea');
    const label = $el.find('[data-label]').get(0);

    let labelHidden;

    const showLabel = (tween = true) => {
        if (!label || labelHidden === false) {
            return;
        }
        labelHidden = false;
        if (!tween) {
            gsap.set(label, { opacity: 1, yPercent: 0 });
            return;
        }
        gsap.timeline()
            .to(label, { duration: 0.3, opacity: 1 }, 0)
            .to(label, { duration: 0.3, yPercent: 0, ease: 'Cubic.easeOut' }, 0);
    };

    const hideLabel = (tween = true) => {
        if (!label || labelHidden === true) {
            return;
        }
        labelHidden = true;
        if (!tween) {
            gsap.set(label, { opacity: 0, yPercent: 100 });
            return;
        }
        gsap.timeline()
            .to(label, { duration: 0.2, opacity: 0 }, 0)
            .to(label, { duration: 0.3, yPercent: 100, ease: 'Cubic.easeIn' }, 0);
    };

    const maybeShowLabel = (tween = true) => {
        const value = $input.val();
        if (value.length) {
            showLabel();
        } else {
            hideLabel();
        }
    };

    const init = () => {
        maybeShowLabel(false);
        $input
            .on('change', maybeShowLabel)
            .on('keyup', maybeShowLabel);
    };

    const destroy = () => {
        $input.off('change keyup');
    };

    return {
        init,
        destroy
    };

};
