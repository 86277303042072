import $ from '../core/Dom';

export default el => {

    const $el = $(el);

    const toggleRelated = toggle => {
        const target = toggle.nextElementSibling;
        const wasExpanded = toggle.getAttribute('aria-expanded') === 'true';
        toggle.setAttribute('aria-expanded', wasExpanded ? 'false' : 'true');
        target.hidden = wasExpanded;
    };

    const onRelatedToggleClick = e => {
        e.preventDefault();
        const toggle = e.triggerTarget;
        $el.find(`[data-related-toggle][aria-expanded="true"]:not([href="${toggle.getAttribute('href')}"])`).get().forEach(toggle => toggleRelated(toggle));
        toggleRelated(e.triggerTarget);
    };

    const init = () => {
        // Init related entries accordion toggles
        $el.find('[data-related-toggle]').each(node => {
            $(node).attr({
                role: 'button',
                'aria-expanded': false,
                tabIndex: 0
            });
        });
        $el.on('click', '[data-related-toggle]', onRelatedToggleClick);
    };

    const destroy = () => {
        $el.off('click');
    };

    return {
        init, destroy
    };

};
