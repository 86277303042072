import $ from '../core/Dom';
import Config from '../core/Config';
import { loadPikaday } from '../lib/async-bundles';

export default (el, props) => {

    const $el = $(el);
    const input = $el.is('input') ? el : $el.find('input').get(0);

    const inModal = !!$el.parent('.modal').length;

    let Pikaday = null;

    if (!input) {
        console.warn('No input to bind datepicker');
        return;
    }

    const i18n = Config.get('i18n.pikaday');

    const {
        minDate = new Date(),
        ariaLabel = null
    } = props || {};

    let picker;

    const createPicker = () => {

        if (!Pikaday) {
            return;
        }

        let opts = {
            field: input,
            firstDay: 1,
            minDate,
            ariaLabel,
            i18n,
            reposition: true,
            toString(date) {
                let day = date.getDate();
                if (day < 10) {
                    day = `0${day}`;
                }
                let month = date.getMonth() + 1;
                if (month < 10) {
                    month = `0${month}`;
                }
                const year = date.getFullYear();
                return `${day}.${month}.${year}`;
            },
        };

        if (inModal) {
            opts = {
                ...opts,
                container: el
            };
        }

        picker = new Pikaday(opts);
    };

    const init = () => {
        if (!Pikaday) {
            loadPikaday(bundle => {
                Pikaday = bundle;
                createPicker();
            });
            return;
        }
        createPicker();
    };

    const destroy = () => {
        if (picker) {
            picker.destroy();
        }
    };

    return {
        init,
        destroy
    };

};
