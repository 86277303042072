import gsap from 'gsap';
import $ from './core/Dom';
import Viewport from './core/Viewport';
import Components from './core/Components';
import Config from './core/Config';
import lazySizes from './lib/lazysizes';
import ViewportHeight from './lib/ViewportHeight';
import { getScrollbarWidth } from './core/utils';
import { triggerSearchModal } from './lib/helpers';
import { openAjaxModal } from './modules/ModalController';

gsap.defaults({ overwrite: 'auto' });

const init = () => {

    Viewport.init();

    Config.set('scrollbarWidth', getScrollbarWidth());
    if (Config.get('scrollbarWidth')) {
        $('html').addClass('has-scrollbar');
    }

    Components.init();
    Viewport.initTabbing();
    ViewportHeight.init();
    lazySizes.init();

    // Init form buttons
    const $body = $('body');
    const onFormBtnClick = e => {
        e.preventDefault();
        const { triggerTarget: target } = e;
        const href = $(target).attr('href');
        openAjaxModal(href, {
            animate: false,
            autoFocusInput: false
        });
    };
    $body.on('click', '[data-formbtn]', onFormBtnClick);

    // Init search buttons
    const onSearchBtnClick = e => {
        e.preventDefault();
        const { triggerTarget: target } = e;
        const query = $(target).data('searchquery');
        triggerSearchModal(query);
    };
    $body.on('click', '[data-searchquery]', onSearchBtnClick);

};

require('doc-ready')(init);
