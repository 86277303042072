/*
*   ~ Project event keys go in here ~
*
*   Usage in components:
*
*   import Events from '@vaersaagod/tools/Events';
*   import * as eventKeys from '../lib/events';
*   Events.publish(eventKeys.SOME_EVENT, 'This ES6 shit is wack');
*
 */

export const SCROLL_LOCKED = 'SCROLL_LOCKED';
export const SCROLL_RELEASED = 'SCROLL_RELEASED';

export const MENU_BEFORE_OPEN = 'MENU_BEFORE_OPEN';
export const MENU_AFTER_OPEN = 'MENU_AFTER_OPEN';
export const MENU_BEFORE_CLOSE = 'MENU_BEFORE_CLOSE';
export const MENU_AFTER_CLOSE = 'MENU_AFTER_CLOSE';

export const MODAL_OPENED = 'MODAL_OPENED';
export const MODAL_CLOSED = 'MODAL_CLOSED';
export const MODAL_UPDATED = 'MODAL_UPDATED';

export const SEARCH_OPEN = 'SEARCH_OPEN';
export const SEARCH_CLOSE = 'SEARCH_CLOSE';

export const REGISTER_STICKY_NAVBAR = 'REGISTER_STICKY_NAVBAR';
export const DEREGISTER_STICKY_NAVBAR = 'DEREGISTER_STICKY_NAVBAR';

export const ALERT_CLOSING = 'ALERT_CLOSING';
export const ALERT_CLOSED = 'ALERT_CLOSED';

export const PROGRAMMATIC_SCROLL_START = 'PROGRAMMATIC_SCROLL_START';
export const PROGRAMMATIC_SCROLL_END = 'PROGRAMMATIC_SCROLL_END';
